import React, { useEffect, useState } from "react";
import { Copyright } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAuthStore, useDataStore } from "../store";
import HalfPhone from "../assets/images/HalfPhone.png";
import { Icon, LazyImage, NotificationModal } from "./index";
import { usePersistData } from "../store";
import FooterBg from "../assets/images/FooterBg.png";
import {
  appleStoreLink,
  centerClass,
  openLinkInNewTab,
  playStoreLink,
} from "../utils";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase/FirebaseConfig";

const ShowCol = ({ title, data }) => {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const { setCountry, setCart } = usePersistData();

  return (
    <div className="flex flex-col space-y-2">
      <span className="text-base font-semibold mb-4 mt-5 lg:mt-0">{title}</span>
      {data.map((item, index) =>
        !user?.admin && item.name === "Notification" ? null : (
          <span
            key={index}
            className="text-sm mb-2 cursor-pointer hover:text-bg-1"
            onClick={() => {
              if (title == "Popular countries") {
                setCountry(item);
                setCart(null);
                navigate(`/checkout`);
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              } else {
                item.handleClick ? item.handleClick() : navigate(item.path);
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }
            }}
          >
            {item.name}
          </span>
        )
      )}
    </div>
  );
};

const Footer = () => {
  const { footer } = useDataStore();
  const navigate = useNavigate();

  const [notifyModal, setNotifyModal] = useState(false);
  const [TopCountries, setTopCountries] = useState([]);

  const arr = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "About",
      path: "/about",
    },
    {
      name: "Destinations",
      path: "/destinations",
    },
    {
      name: "Review",
      path: "/review",
    },
    {
      name: "Career",
      path: "/career",
    },
    {
      name: "Notification",
      handleClick: () => setNotifyModal(true),
    },
  ];
  const info = [
    {
      name: "Privacy & Cookie Policy",
      path: "/privacyPolicy",
    },
    {
      name: "Terms & Conditions",
      path: "/terms&conditions",
    },
  ];

  const navigateToHome = () => {
    navigate("/");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const fetchPopular = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "popular"));
      const destinationsData = querySnapshot.docs.map((doc) => doc.data());

      const arr = destinationsData[0].countries;

      const countriesRef = collection(db, "countries-meta");

      const data = query(countriesRef, where("code", "in", arr));

      const docs = await getDocs(data);

      const popularCountries = docs.docs.map((doc) => doc.data());
      const limitedCountries = popularCountries.slice(0, 6);
      setTopCountries(limitedCountries);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPopular();
  }, []);

  return (
    <div className={`${centerClass} md:w-[90%] w-full`}>
      {footer.isFooterImage && (
        <div
          className={`w-full md:flex sm:justify-evenly md:rounded-[32px] mt-[50px] md:mt-[100px] text-bg-4`}
          style={{
            backgroundImage: `url(${FooterBg})`,
            backgroundSize: "cover",
          }}
        >
          <div className="flex w-full flex-col py-[50px] md:pl-[50px] px-4">
            <div className="flex items-center space-x-4 mb-6">
              <Icon iconName="logoIcon" className={`h-6 w-6 text-bg-4`} />
              <span className="text-xl font-semibold">
                Get The SimFree app!
              </span>
            </div>
            <span className="font-bold text-[30px] md:text-[60px] leading-[100%]">
              Download now and embark on your journey hassle-free.
            </span>
            <span className="my-6 font-medium text-2xl">
              Say goodbye to roaming worries and hello to effortless travel
              communication with the SimFree app. Download today and unlock a
              world of convenience!
            </span>

            <div className="flex items-center space-x-4">
              <span
                className="cursor-pointer"
                onClick={() => {
                  openLinkInNewTab(appleStoreLink);
                }}
              >
                <Icon iconName="appStore" />
              </span>

              <span
                className="cursor-pointer"
                onClick={() => {
                  openLinkInNewTab(playStoreLink);
                }}
              >
                <Icon iconName="playStore" />
              </span>
            </div>

            <span className="text-base font-medium mt-4">
              Unlock the potential of your device with Simfree.io's cutting-edge
              eSIM solutions.
            </span>
          </div>
          <div className="flex items-end justify-center px-4 md:pr-[50px]">
            <LazyImage
              src={HalfPhone}
              alt="HalfPhone"
              className="w-[270px] md:w-[370px]"
            />
          </div>
        </div>
      )}

      <div className="my-[50px] md:my-[100px] space-y-4 mx-4 lg:flex justify-between">
        <div className="flex flex-col lg:max-w-[40%]">
          <span
            className="cursor-pointer"
            onClick={() => {
              navigateToHome();
            }}
          >
            <Icon iconName="logo" />
          </span>
          <span className="text-black text-sm text-justify mt-4 md:mt-8">
            For Simfree, emphasize the technology aspect and focus on how the
            service simplifies connectivity for global young travelers. You
            could describe Simfree as a forward-thinking company, dedicated to
            eliminating the traditional barriers in global communication by
            providing instant, secure, and seamless eSIM services to travelers
            worldwide.
          </span>
        </div>
        <div className="flex flex-col md:flex-row lg:max-w-[50%] justify-between w-full">
          <ShowCol title="Popular countries" data={TopCountries} />
          <ShowCol title="Simfree eSIM" data={arr} />
          <ShowCol title="Info" data={info} />
        </div>
      </div>
      <NotificationModal
        open={notifyModal}
        onClose={() => setNotifyModal(false)}
      />
      <div className="w-full justify-center flex mb-8">
        <span
          onClick={() => {
            navigate("/privacyPolicy");
          }}
          className="text-base items-center flex text-text-8"
        >
          <Copyright sx={{ fontSize: "16px" }} />
          2024 Simfree. All rights reserved.
        </span>
      </div>
    </div>
  );
};

export default Footer;
