import React from "react";
import fullBag from "../../assets/images/fullBag.png";
import compass from "../../assets/images/fullCompass.png";
import dice from "../../assets/images/dice.png";
import { centerClass } from "../../utils/constants";
import { LazyImage } from "../../components";

const data = [
  {
    title: "Simple",
    desc: "Embrace the future of mobile connectivity",
    img: compass,
  },
  {
    title: "Mission",
    desc: "Embrace the future of mobile connectivity",
    img: dice,
  },
];

export default function AdsPoster() {
  return (
    <div
      className={`${centerClass} my-[50px] md:my-[100px] md:flex justify-between overflow-hidden`}
    >
      <div className="w-full group md:w-[48%] overflow-hidden bg-bg-4 hover:bg-bg-6 rounded-3xl flex flex-col space-y-2">
        <div className="p-4 md:p-8">
          <p className="text-3xl md:text-6xl font-bold text-white my-2">
            Traveling with No Doubt
          </p>
          <p className="text-base md:text-xl font-medium text-white">
            We prioritise reliability above all else, ensuring that our eSIM
            solutions deliver seamless connectivity wherever you are.
          </p>
        </div>

        <div className="flex justify-between">
          <p className="text-xs md:text-base font-medium text-white p-4 md:p-8">
            Our flexible plans cater to your unique needs, whether you're a
            frequent traveller, digital nomad, or business professional.
          </p>
          <img
            src={fullBag}
            alt="Bag"
            className="inline-block object-contain max-w-[50%] -rotate-12 h-[220px] -mb-20 transition-transform duration-300 transform group-hover:rotate-12 group-hover:scale-110"
          />
        </div>
      </div>

      <div className="w-full md:w-[48%] flex flex-col justify-between">
        {data.map((v, i) => {
          const classes =
            i === 0
              ? "bg-bg-2 text-bg-4 hover:bg-bg-5 mt-4 md:mt-0 "
              : "bg-bg-1 text-white hover:bg-bg-7 mt-4";
          return (
            <div
              key={i}
              className={`w-full group flex justify-between max-h-[200px] overflow-hidden rounded-3xl ${classes}`}
            >
              <div className="p-4 md:p-8 w-1/2">
                <p className="text-3xl md:text-6xl font-bold mb-4">{v.title}</p>
                <p className="text-base md:text-xl font-medium">{v.desc}</p>
              </div>
              <img
                src={v.img}
                className="inline-block max-w-[50%] !h-full object-contain transition-transform duration-300 transform group-hover:rotate-12 group-hover:scale-125 scale-110"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
