import { create } from "zustand";

const useDataStore = create((set) => ({
  noLayout: false,
  footer: {
    isFooter: false,
    isFooterImage: false,
  },
  setFooter: (data) =>
    set((state) => ({
      footer: data,
    })),
  setNoLayout: (data) =>
    set((state) => ({
      noLayout: data,
    })),
}));

export default useDataStore;
