import React from "react";
import { usePersistData } from "../../store";
import { ButtonComp, Icon, LazyImage } from "../../components";
import { Tooltip } from "@mui/material";

const ShowPlans = ({ plans }) => {
  const { cart, setCart, country } = usePersistData();

  return (
    <div className="mt-6 gap-3 w-full grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3">
      {plans.length ? (
        plans.map((item, index) => {
          const isSelected = item.id == cart?.id;
          return (
            <div
              key={index}
              className={`p-4 rounded-3xl space-y-4 border w-full ${
                isSelected
                  ? "border-bg-1 linearBgLight"
                  : "border-border-1 bg-bg-2"
              }`}
            >
              <div className="flex justify-between min-w-[200px]">
                <span className="flex font-semibold text-base items-center">
                  <LazyImage
                    src={country.flagUrl}
                    alt={country.name}
                    className="w-6 h-6 mr-2 object-cover rounded-full"
                  />
                  <Tooltip title={country.name} placement={"top"} arrow>
                    <span className="truncate lg:max-w-24">{country.name}</span>
                  </Tooltip>
                </span>
                <span>{item.days} Days</span>
              </div>

              <div className="flex justify-between min-w-[200px] text-2xl font-semibold">
                <span className="flex items-center">
                  <Icon
                    iconName="network"
                    className={`h-6 w-6 text-black mr-2`}
                  />
                  {item.size} GB
                </span>
                <span className="">${item.price}</span>
              </div>

              <ButtonComp
                text={isSelected ? "Cancel" : "Select"}
                handleClick={() => setCart(isSelected ? null : item)}
                bgColor={`${
                  isSelected ? "!bg-white !linearText border border-bg-1" : ""
                }`}
              />
            </div>
          );
        })
      ) : (
        <div className="text-3xl font-bold text-text-7">No Plans Available</div>
      )}
    </div>
  );
};

export default ShowPlans;
