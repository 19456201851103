import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth, db } from "../firebase/FirebaseConfig";
import { doc, getDoc, setDoc } from "firebase/firestore";

export const signUpEmailAndPassword = async (userData, cb) => {
  try {
    const { email, password } = userData;
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;

    const userRef = doc(db, `users`, user.uid);

    const data = {
      id: user.uid,
      email: email,
      admin: false,
    };

    await setDoc(userRef, data);
    cb(data, true);
  } catch (error) {
    console.log("error.code", error.code);
    let errorMessage = "An error occurred during signup.";
    switch (error.code) {
      case "auth/weak-password":
        errorMessage =
          "The password you entered is too weak. Please choose a stronger password.";
        break;
      case "auth/email-already-in-use":
        errorMessage =
          "The email address you entered is already in use. Please try a different email.";
        break;
      case "auth/invalid-email":
        errorMessage =
          "The email address you entered is invalid. Please enter a valid email address.";
        break;
      default:
        console.error("Unhandled error:", error);
        errorMessage = "An unexpected error occurred. Please try again later.";
    }

    cb(errorMessage, false);
  }
};

export const signInEmailAndPassword = async (email, password, cb) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    const docRef = doc(db, "users", user.uid);
    const userDocument = await getDoc(docRef);

    if (userDocument.exists()) {
      cb(userDocument.data(), true);
    } else {
      cb("The user doesn't exist in the database", false);
    }
  } catch (error) {
    console.log("error.code", error.code);
    let errorMessage = "An error occurred during signin.";
    switch (error.code) {
      case "auth/wrong-password":
      case "auth/user-not-found":
      case "auth/invalid-credential":
        errorMessage = "Invalid email or password. Please try again.";
        break;
      case "auth/invalid-email":
        errorMessage = "The email address you entered is invalid.";
        break;
      default:
        console.error("Unhandled error:", error);
        errorMessage = "An unexpected error occurred. Please try again later.";
    }

    cb(errorMessage, false);
  }
};

export const resetPassword = async (email, cb) => {
  try {
    await sendPasswordResetEmail(auth, email);
    cb("Email sent", true);
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    cb(errorMessage, false);
    console.log(errorCode, "errorCode", errorMessage);
  }
};
