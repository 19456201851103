import React from "react";

function Network({ className = "w-6 h-6" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
      className={className}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2.667 16h26.666M2.667 16c0 7.364 5.97 13.333 13.333 13.333M2.667 16C2.667 8.636 8.637 2.667 16 2.667M29.333 16c0 7.364-5.97 13.333-13.333 13.333M29.333 16c0-7.364-5.97-13.333-13.333-13.333m0 0A20.4 20.4 0 0121.333 16 20.4 20.4 0 0116 29.333m0-26.666A20.4 20.4 0 0010.667 16 20.4 20.4 0 0016 29.333"
      ></path>
    </svg>
  );
}

export default Network;
