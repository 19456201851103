import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase/FirebaseConfig";
import { PopularCountries, ShowAllCountries } from "../content/Destination";
import { CircularProgress } from "@mui/material";
import { centerClass } from "../utils";
import globe from "../assets/images/globe.png";
import fullBag from "../assets/images/fullBag.png";
import { useDataStore } from "../store";
import { motion } from "framer-motion";
import { LazyImage } from "../components";

const Destinations = () => {
  const [allCountries, setAllCountries] = useState([]);
  const [topCountries, setTopCountries] = useState([]);
  const [allLoading, setAllLoading] = useState(true);

  const { setFooter } = useDataStore();

  useEffect(() => {
    setFooter({
      isFooter: true,
      isFooterImage: true,
    });

    getDocs(collection(db, "countries-meta")).then((res) => {
      setAllCountries(res.docs.map((doc) => doc.data()));
    });

    fetchPopular();
  }, []);

  const fetchPopular = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "popular"));
      const destinationsData = querySnapshot.docs.map((doc) => doc.data());

      const arr = destinationsData[0].countries;

      const countriesRef = collection(db, "countries-meta");

      const data = query(countriesRef, where("code", "in", arr));

      const docs = await getDocs(data);

      const popularCountries = docs.docs.map((doc) => doc.data());

      setTopCountries(popularCountries);
      setAllLoading(false);
    } catch (error) {
      setAllLoading(false);
      console.error(error);
    }
  };

  return allLoading ? (
    <div className="flex justify-center items-center h-screen">
      <CircularProgress />
    </div>
  ) : (
    <div>
      <div
        className={`my-[50px] text-text-1 flex justify-between ${centerClass}`}
      >
        <div
          className={`w-full md:w-[791px] flex flex-col items-center md:items-start text-center md:text-left`}
        >
          <span className="flex items-center p-[10px] text-[18px] border border-border-1 rounded-full w-max">
            <LazyImage src={globe} className="mr-3" />
            Explore
          </span>
          <span className="text-[56px] md:text-[100px] my-8 leading-[100%] font-bold">
            Explore the World with Simfree’s eSIM
          </span>
          <span className="font-medium text-xl">
            At Simfree.io, we understand the thrill of exploring new
            destinations without worrying about connectivity hurdles.
          </span>
        </div>

        <div className="flex flex-col justify-center">
          <motion.img
            src={fullBag}
            className="w-[320px] object-contain hidden md:block"
            animate={{ y: [20, -20, 20] }}
            transition={{ duration: 4, repeat: Infinity, ease: "easeInOut" }}
          />
        </div>
      </div>
      <div>
        <PopularCountries topCountries={topCountries} />
      </div>

      <div id="explorer">
        <ShowAllCountries allCountries={allCountries} />
      </div>
    </div>
  );
};

export default Destinations;
