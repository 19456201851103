import { Modal } from "@mui/material";
import React from "react";

const ModalComp = ({ open, handleClose, children, classes }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <div className="w-full h-full flex justify-center items-center">
        <div
          className={`w-[90%] md:w-[500px] flex flex-col space-y-4 bg-white p-8 rounded-3xl ${classes}`}
        >
          {children}
        </div>
      </div>
    </Modal>
  );
};

export default ModalComp;
