import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Badge } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { ButtonComp, Icon, Footer, LazyImage } from "./index";
import bagIcon from "../assets/images/bag.png";
import {
  appleStoreLink,
  centerClass,
  layoutDataArr,
  openLinkInNewTab,
  playStoreLink,
  stringConstants,
} from "../utils";
import { useDataStore, usePersistData, useAuthStore } from "../store";
import { auth } from "../firebase/FirebaseConfig";

const BorderIcon = ({ iconName, ...rest }) => {
  return (
    <span className="flex items-center justify-center w-10 h-10 border border-border-1 rounded-2xl">
      <Icon iconName={iconName} {...rest} />
    </span>
  );
};

const Layout = ({ children }) => {
  const [isMenu, setIsMenu] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isMenu2, setIsMenu2] = useState(false);

  const menuRef = useRef(null);

  const navigate = useNavigate();
  const { user } = useAuthStore();
  const { pathname } = useLocation();

  const { footer, noLayout } = useDataStore();
  const { cart } = usePersistData();
  const { updateUser } = useAuthStore();

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenu(false);
      setIsMenu2(false);
    }
  };

  const signout = async () => {
    setIsMenu2(false);
    if (!user) {
      navigate("/signin");
    } else {
      await auth.signOut();
      updateUser(null);
      navigate("/signin");
    }
  };

  useEffect(() => {
    const handleClick = handleClickOutside;

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <div className="min-h-screen flex flex-col justify-between">
      <div>
        {noLayout ? null : (
          <div
            ref={menuRef}
            className={`relative py-6 ${centerClass} ${
              isMenu &&
              "shadow-xl border-border-1 border md:border-0 rounded-t-[32px] md:rounded-none md:shadow-none"
            }`}
          >
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center">
                <span className="hidden md:block">
                  <Menu
                    className="cursor-pointer"
                    sx={{
                      fontSize: 24,
                      color: "#111111",
                    }}
                    onClick={() => {
                      setIsMenu(!isMenu);
                      setIsMenu2(false);
                    }}
                  />
                </span>
                <span
                  onClick={() => {
                    setIsMenu(false);
                    setIsMenu2(false);
                    navigate("/");
                  }}
                >
                  <Icon
                    iconName="logo"
                    className={`mr-2 ml-4 cursor-pointer`}
                  />
                </span>
              </div>
              <div className="items-center hidden md:flex">
                <span
                  className="mx-6 h-8 w-8 cursor-pointer"
                  onClick={() => navigate("/checkout")}
                >
                  <Badge badgeContent={cart ? 1 : 0} color="error">
                    <Icon iconName="Cart" className={`h-8 w-8 text-black`} />
                  </Badge>
                </span>
                <span
                  onClick={() => {
                    setIsMenu(false);
                    setIsMenu2(!isMenu2);
                  }}
                  className={`hidden md:block rounded-r-2xl cursor-pointer items-center`}
                >
                  <Icon
                    iconName="profileIcon"
                    className={`h-8 w-8 text-black`}
                  />
                </span>
                <ButtonComp
                  className="ml-4 "
                  handleClick={() => navigate("/destinations")}
                  bgColor="s"
                  animate={{
                    onHoverStart: () => setIsHovered(true),
                    onHoverEnd: () => setIsHovered(false),
                    animate: {
                      backgroundColor: isHovered ? "#FFAF00" : "#FFCA5F",
                      color: isHovered ? "#fff" : "",
                    },
                    transition: { duration: 0.5 },
                  }}
                />
              </div>
              <div className="flex gap-2 md:hidden items-center cursor-pointer">
                <span
                  onClick={() => {
                    setIsMenu(false);
                    setIsMenu2(!isMenu2);
                  }}
                  className={`rounded-r-2xl cursor-pointer items-center`}
                >
                  <Icon
                    iconName="profileIcon"
                    className={`h-6 w-6 text-black`}
                  />
                </span>
                <span
                  onClick={() => {
                    setIsMenu(!isMenu);
                    setIsMenu2(false);
                  }}
                >
                  <Icon iconName={"menuIcon"} />
                </span>
              </div>
            </div>
            {isMenu2 && (
              <div className="absolute space-y-6 right-0 md:right-36 w-max bg-white p-2 md:p-4 top-[80%] z-50 rounded-[16px] shadow-xl border-border-1 border border-t-0 md:rounded-t-[16px]">
                {user ? (
                  <>
                    <span
                      onClick={() => {
                        setIsMenu2(false);
                        navigate("/myesim");
                      }}
                      className="flex gap-2 font-semibold cursor-pointer text-[18px] hover:text-text-2"
                    >
                      <Icon
                        iconName="esimIcon"
                        className={`h-6 w-6 text-black`}
                      />
                      My eSim's
                    </span>
                  </>
                ) : null}
                <span
                  onClick={() => {
                    signout();
                  }}
                  className="flex gap-2 items-center font-semibold cursor-pointer text-[18px] hover:text-text-2"
                >
                  <Icon
                    iconName="logoutIcon"
                    className={`h-5 w-5 text-black`}
                  />
                  {!user ? "Sign In" : "Sign Out"}
                </span>
              </div>
            )}
            {isMenu && (
              <div className="absolute w-full bg-white p-4 md:p-10 top-full z-50 rounded-[32px] shadow-xl border-border-1 border rounded-t-none border-t-0 md:rounded-t-[32px]">
                <div className="md:flex justify-between">
                  <div className="space-y-10">
                    {layoutDataArr.map((item, index) => (
                      <div className="" key={index}>
                        <span className="flex flex-row items-center space-x-5">
                          <BorderIcon
                            iconName={item.icon}
                            className={`h-6 w-6 ${item.iconClasses}`}
                          />
                          <span
                            onClick={() => {
                              setIsMenu(false);
                              navigate(item.navigate);
                            }}
                            className="font-bold cursor-pointer text-[32px] hover:text-text-2"
                          >
                            {item.name}
                          </span>
                        </span>
                        {item.links && (
                          <div className="ml-[60px] gap-4 mt-5 flex flex-row flex-wrap items-center">
                            {item.links.map((link, index) => (
                              <div key={index}>
                                <span
                                  key={index}
                                  className="text-xl font-medium cursor-pointer hover:text-text-2"
                                  onClick={() => {
                                    setIsMenu(false);
                                    if (link.isScreen) {
                                      navigate(link.screen);
                                    }

                                    if (link?.moveId) {
                                      const timer =
                                        link?.screen === "destinations" &&
                                        pathname !== "/destinations"
                                          ? 3000
                                          : 300;

                                      setTimeout(() => {
                                        const element = document.getElementById(
                                          link?.moveId
                                        );
                                        element?.scrollIntoView({
                                          behavior: "smooth",
                                        });
                                      }, timer);
                                    }
                                  }}
                                >
                                  {link.name}
                                </span>
                                <span className="ml-4 bg-border-3 w-[1px] h-5" />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>

                  <div>
                    <div className="relative linearBg w-full md:w-[392px] mt-4 md:mt-0 h-max rounded-3xl p-4 md:p-8">
                      <span className="top-0 right-0 absolute">
                        <LazyImage src={bagIcon} />
                      </span>
                      <span className="text-[24px] md:text-[60px] font-bold leading-[60px]">
                        {stringConstants.simpleTravel}
                      </span>
                      <p className="text-sm md:text-xl font-medium p-0 mt-6">
                        Ready to experience the future of connectivity? Join
                        thousands of satisfied customers who rely on Simfree.io
                        for seamless, reliable eSIM solutions.
                      </p>
                    </div>

                    <div className="flex flex-row items-center justify-between mt-4">
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          openLinkInNewTab(appleStoreLink);
                        }}
                      >
                        <Icon iconName="appStore" fill="#111" color="#fff" />
                      </span>

                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          openLinkInNewTab(playStoreLink);
                        }}
                      >
                        <Icon iconName="playStore" fill="#111" color="#fff" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {children}
      </div>

      {footer.isFooter && <Footer />}
    </div>
  );
};

export default Layout;
