import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SliderComp = ({
  children,
  setSlider,
  extraSettings = {},
  settings = {
    show: 3,
    768: 1,
    1024: 2,
    1440: 3,
  },
}) => {
  return (
    <div className="w-full">
      <Slider
        ref={(slider) => setSlider(slider)}
        dots={false}
        infinite={true}
        slidesToScroll={1}
        slidesToShow={settings.show}
        autoplay={true}
        speed={500}
        responsive={[
          {
            breakpoint: 768,
            settings: {
              slidesToShow: settings[768],
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: settings[1024],
            },
          },
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: settings[1440],
            },
          },
        ]}
        {...extraSettings}
      >
        {children}
      </Slider>
    </div>
  );
};

export default SliderComp;
