import React from "react";
import { Icon, LazyImage, SliderComp } from "../../components";
import quoteIcon from "../../assets/images/quoteIcon.png";
import { centerClass, testimonials } from "../../utils";

export default function Testimonials() {
  return (
    <>
      <div className={`my-[50px] md:my-[100px] overflow-hidden ${centerClass}`}>
        <div className="w-full space-x-2 flex items-center justify-center">
          <Icon iconName="logoIcon" className={`h-6 w-6 linearText`} />
          <p className="text-base md:text-xl font-medium linearText text-center">
            Testimonials
          </p>
        </div>
        <p className="text-3xl md:text-6xl font-bold text-black mt-4 w-full text-center">
          Friends of Family
        </p>
        <p className="text-base md:text-xl font-medium text-black mt-4 w-full text-center">
          What Our Customers Say?
        </p>
      </div>
      {[1, 2].map((v, i) => {
        const arr =
          i === 0 ? testimonials.slice(0, 10) : testimonials.slice(10);
        return (
          <div
            className={`flex w-full h-max overflow-hidden maskedDiv max-w-[1440px] mx-auto ${
              i === 1 ? "hidden md:flex" : ""
            }`}
            key={i}
          >
            <SliderComp
              setSlider={() => {}}
              extraSettings={{
                speed: 5000,
                autoplaySpeed: 0,
                cssEase: "linear",
                arrows: false,
                pauseOnHover: false,
                rtl: i === 1,
              }}
            >
              {arr.map((item, index) => (
                <div
                  key={index}
                  className="!w-[95%] !h-[300px] !flex flex-col justify-between bg-bg-2 border shadow p-6 md:p-8 rounded-3xl mb-4 "
                >
                  <LazyImage className="w-8 h-6" src={quoteIcon} />
                  <p className="font-medium text-black mt-4 text-justify text-sm">
                    {item.desc}
                  </p>

                  <div className="w-full flex items-center mt-4 gap-[20px]">
                    <LazyImage className="w-14 h-14 rounded-full " src={item.image} />
                    <p className="text-base md:text-xl font-semibold text-black ">
                      {item.name}{" "}
                    </p>
                  </div>
                </div>
              ))}
            </SliderComp>
          </div>
        );
      })}
    </>
  );
}
