import React, { useState } from "react";
import { Icon } from "../../components";
import { centerClass, faqApplication, faqPayment } from "../../utils";
import { Minimize, Add } from "@mui/icons-material";
import { AnimatePresence, motion } from "framer-motion";

export default function FaqSection() {
  const [showCollapse, setshowCollapse] = useState(0);
  const [selected, setSelected] = useState("Application");
  const [faqs, setFaqs] = useState(faqApplication);

  const options = [
    {
      name: "Application",
      dataArr: faqApplication,
    },
    {
      name: "Payment Process",
      dataArr: faqPayment,
    },
  ];

  return (
    <div
      className={`${centerClass} flex bg-bg-2 my-[50px] md:my-[100px] justify-between`}
    >
      <div className={`${centerClass} my-[50px] md:my-[100px] rounded-3xl`}>
        <div className="w-40 gap-3 flex items-center">
          <Icon iconName="logoIcon" className={`h-6 w-6 text-bg-4`} />
          <span className="text-base md:text-xl font-medium text-bg-4 text-center">
            FAQ
          </span>
        </div>

        <p className="md:w-[50%] w-full text-3xl md:text-6xl font-bold text-black my-4">
          Frequently Asked Question
        </p>

        <div className="w-full md:flex">
          <div className="w-full md:w-[30%] mt-4 flex flex-col justify-between">
            <div className="flex flex-col space-y-3">
              {options.map((e, i) => {
                const isSelected = selected === e.name;
                return (
                  <span
                    key={i}
                    onClick={() => {
                      setSelected(e.name);
                      setFaqs(e.dataArr);
                    }}
                    className={`cursor-pointer text-base md:text-xl font-semibold hover:text-bg-4 ${
                      isSelected ? "text-bg-4" : "text-text-7"
                    }`}
                  >
                    {e.name}
                  </span>
                );
              })}
            </div>

            <div className="md:mt-64 hidden md:block">
              <p className="cursor-pointer font-medium text-gray-400">
                Last updated
              </p>
              <p className="cursor-pointer font-medium text-gray-400 ">
                20 March 2024
              </p>
            </div>
          </div>

          <div className="w-full mt-4">
            {faqs?.map((item, i) => {
              const isOpened = showCollapse === i;
              return (
                <div className="flex flex-col space-y-4" key={i}>
                  <div
                    key={i}
                    onClick={() => setshowCollapse(isOpened ? null : i)}
                    className="w-full cursor-pointer flex justify-between text-black"
                  >
                    <p className="text-2xl font-semibold">{item.ques}</p>
                    {isOpened ? (
                      <Minimize className="text-text-2" />
                    ) : (
                      <Add className="text-text-2" />
                    )}
                  </div>
                  <AnimatePresence initial={false}>
                    {isOpened && (
                      <motion.div
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={{
                          open: { opacity: 1, height: "auto" },
                          collapsed: { opacity: 0, height: 0 },
                        }}
                        transition={{ duration: 0.3 }}
                      >
                        <p className="text-lg font-light">{item.desc}</p>
                        {/* <div className="flex gap-8">
                          <span>
                            <ThumbUpAlt /> Helpfull
                          </span>
                          <span>
                            <ThumbDownAlt /> Not Helpfull
                          </span>
                        </div> */}
                      </motion.div>
                    )}
                  </AnimatePresence>
                  <div className="w-full bg-border-2 h-[1px] !my-8" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
