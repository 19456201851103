import React from "react";

function SummaryBasket({ className = "w-20 h-20" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 80 80"
      className={className}
    >
      <path
        fill="url(#paint0_linear_399_8703)"
        fillRule="evenodd"
        d="M65 44.521H15v23.14a2.5 2.5 0 001.781 2.395l22.5 6.75c.469.14.969.14 1.438 0l22.5-6.75A2.5 2.5 0 0065 67.661v-23.14z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint1_linear_399_8703)"
        fillRule="evenodd"
        d="M65 44.521H40s-.242 32.39 0 32.39c.243 0 .484-.035.72-.105l22.5-6.75A2.5 2.5 0 0065 67.661v-23.14z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint2_linear_399_8703)"
        fillRule="evenodd"
        d="M40 52.633L15 44.52l-6.343 9.405a1.25 1.25 0 00.65 1.889L31.8 63.123a1.25 1.25 0 001.422-.488L40 52.633z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint3_linear_399_8703)"
        fillRule="evenodd"
        d="M65 44.521l-25 8.111 6.779 10.003c.312.461.892.66 1.421.488l22.494-7.308a1.25 1.25 0 00.65-1.889L65 44.521z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint4_linear_399_8703)"
        fillRule="evenodd"
        d="M33.95 27.483a1.25 1.25 0 00-1.42-.486l-22.496 7.31a1.248 1.248 0 00-.65 1.887L15 44.52l25-8.139s-4.49-6.603-6.05-8.9z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint5_linear_399_8703)"
        fillRule="evenodd"
        d="M70.616 36.194a1.25 1.25 0 00-.65-1.888c-4.748-1.543-18.81-6.112-22.495-7.308a1.25 1.25 0 00-1.42.485L40 36.383l25 8.138 5.616-8.327z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint6_linear_399_8703)"
        fillRule="evenodd"
        d="M65 44.521l-25-8.139-25 8.14 25 8.11 25-8.11z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint7_linear_399_8703)"
        fillRule="evenodd"
        d="M40 52.633v-16.25L15 44.52l25 8.111z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint8_linear_399_8703)"
        fillOpacity="0.6"
        fillRule="evenodd"
        d="M34.977 39.972c-.52-.34-.988-.703-1.404-1.084a1.25 1.25 0 00-1.69 1.84c.51.47 1.087.918 1.727 1.337a1.25 1.25 0 001.367-2.093z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint9_linear_399_8703)"
        fillOpacity="0.6"
        fillRule="evenodd"
        d="M31.082 36.133a10.862 10.862 0 01-.942-1.517 1.251 1.251 0 00-2.229 1.134c.318.623.703 1.246 1.159 1.865a1.25 1.25 0 002.012-1.483z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint10_linear_399_8703)"
        fillOpacity="0.6"
        fillRule="evenodd"
        d="M29.255 31.031c.013-.503.087-.992.222-1.461a1.25 1.25 0 00-2.404-.689 8.392 8.392 0 00-.318 2.088 1.25 1.25 0 002.5.062z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint11_linear_399_8703)"
        fillOpacity="0.6"
        fillRule="evenodd"
        d="M31.177 26.856a7.376 7.376 0 011.075-.821 1.251 1.251 0 00-1.334-2.115 9.82 9.82 0 00-1.44 1.102 1.25 1.25 0 001.7 1.834z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint12_linear_399_8703)"
        fillOpacity="0.6"
        fillRule="evenodd"
        d="M35.826 24.7a14.159 14.159 0 011.998-.22 1.251 1.251 0 00-.106-2.499c-.826.035-1.608.124-2.347.26a1.25 1.25 0 00.455 2.459z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint13_linear_399_8703)"
        fillOpacity="0.6"
        fillRule="evenodd"
        d="M42.342 24.626a29.944 29.944 0 002.98-.106 1.251 1.251 0 00-.222-2.49c-.843.074-1.752.109-2.73.096a1.25 1.25 0 00-.028 2.5z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint14_linear_399_8703)"
        fillOpacity="0.6"
        fillRule="evenodd"
        d="M49.43 23.754c1.237-.379 2.282-.864 3.157-1.42a1.251 1.251 0 00-1.344-2.109c-.703.447-1.547.832-2.545 1.137a1.25 1.25 0 00.732 2.392z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint15_linear_399_8703)"
        fillOpacity="0.6"
        fillRule="evenodd"
        d="M55.793 18.835c.59-1.22.816-2.51.759-3.754a1.25 1.25 0 00-2.498.117 5.269 5.269 0 01-.512 2.55 1.251 1.251 0 002.25 1.087z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint16_linear_399_8703)"
        fillOpacity="0.6"
        fillRule="evenodd"
        d="M54.997 10.661c-.912-1.231-2.148-2.088-3.495-2.365a1.252 1.252 0 00-1.477.974A1.253 1.253 0 0051 10.746c.778.159 1.463.693 1.989 1.404a1.25 1.25 0 102.009-1.489z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint17_linear_399_8703)"
        fillRule="evenodd"
        d="M38.667 8.539c1.352-7.659 13.074-7.164 6.25 0h-6.25z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint18_linear_399_8703)"
        fillRule="evenodd"
        d="M38.667 10.479c1.352 7.657 13.074 7.163 6.25 0h-6.25z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint19_linear_399_8703)"
        fillOpacity="0.6"
        fillRule="evenodd"
        d="M38.555 10.771h8.37a1.25 1.25 0 000-2.5h-8.37a1.251 1.251 0 000 2.5z"
        clipRule="evenodd"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_399_8703"
          x1="15"
          x2="65"
          y1="60.716"
          y2="60.716"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C5E2F8"></stop>
          <stop offset="1" stopColor="#A4BBDB"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_399_8703"
          x1="39.893"
          x2="65"
          y1="60.716"
          y2="60.716"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C5E2F8"></stop>
          <stop offset="1" stopColor="#94D0FA"></stop>
          <stop offset="1" stopColor="#8DA3BE"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_399_8703"
          x1="15.167"
          x2="18.661"
          y1="44.511"
          y2="67.628"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDECF7"></stop>
          <stop offset="1" stopColor="#CADCF0"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_399_8703"
          x1="40.101"
          x2="66.456"
          y1="52.547"
          y2="67.705"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDECF7"></stop>
          <stop offset="1" stopColor="#C5E2F8"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_399_8703"
          x1="11.728"
          x2="15.499"
          y1="39.67"
          y2="21.892"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E9F3FC"></stop>
          <stop offset="1" stopColor="#CADCF0"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_399_8703"
          x1="46.693"
          x2="48.041"
          y1="26.696"
          y2="48.64"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDECF7"></stop>
          <stop offset="1" stopColor="#C5E2F8"></stop>
        </linearGradient>
        <linearGradient
          id="paint6_linear_399_8703"
          x1="15"
          x2="65"
          y1="44.508"
          y2="44.508"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C5E2F8"></stop>
          <stop offset="1" stopColor="#87C7FA"></stop>
        </linearGradient>
        <linearGradient
          id="paint7_linear_399_8703"
          x1="42.56"
          x2="43.099"
          y1="36.019"
          y2="56.21"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C5E3F8"></stop>
          <stop offset="1" stopColor="#94CCFA"></stop>
        </linearGradient>
        <linearGradient
          id="paint8_linear_399_8703"
          x1="33.511"
          x2="33.511"
          y1="38.559"
          y2="42.269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009AFF"></stop>
          <stop offset="1" stopColor="#08F"></stop>
        </linearGradient>
        <linearGradient
          id="paint9_linear_399_8703"
          x1="29.55"
          x2="29.55"
          y1="33.933"
          y2="38.123"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009AFF"></stop>
          <stop offset="1" stopColor="#08F"></stop>
        </linearGradient>
        <linearGradient
          id="paint10_linear_399_8703"
          x1="28.14"
          x2="28.14"
          y1="27.975"
          y2="32.249"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009AFF"></stop>
          <stop offset="1" stopColor="#08F"></stop>
        </linearGradient>
        <linearGradient
          id="paint11_linear_399_8703"
          x1="30.957"
          x2="30.957"
          y1="23.728"
          y2="27.189"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009AFF"></stop>
          <stop offset="1" stopColor="#08F"></stop>
        </linearGradient>
        <linearGradient
          id="paint12_linear_399_8703"
          x1="36.685"
          x2="36.685"
          y1="21.98"
          y2="24.721"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009AFF"></stop>
          <stop offset="1" stopColor="#08F"></stop>
        </linearGradient>
        <linearGradient
          id="paint13_linear_399_8703"
          x1="43.784"
          x2="43.784"
          y1="22.025"
          y2="24.629"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009AFF"></stop>
          <stop offset="1" stopColor="#08F"></stop>
        </linearGradient>
        <linearGradient
          id="paint14_linear_399_8703"
          x1="50.49"
          x2="50.49"
          y1="20.029"
          y2="23.808"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009AFF"></stop>
          <stop offset="1" stopColor="#08F"></stop>
        </linearGradient>
        <linearGradient
          id="paint15_linear_399_8703"
          x1="54.989"
          x2="54.989"
          y1="13.89"
          y2="19.542"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009AFF"></stop>
          <stop offset="1" stopColor="#08F"></stop>
        </linearGradient>
        <linearGradient
          id="paint16_linear_399_8703"
          x1="52.621"
          x2="52.621"
          y1="8.271"
          y2="12.655"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009AFF"></stop>
          <stop offset="1" stopColor="#08F"></stop>
        </linearGradient>
        <linearGradient
          id="paint17_linear_399_8703"
          x1="38.667"
          x2="47.036"
          y1="5.759"
          y2="5.759"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E9F3FC"></stop>
          <stop offset="1" stopColor="#CADCF0"></stop>
        </linearGradient>
        <linearGradient
          id="paint18_linear_399_8703"
          x1="38.667"
          x2="47.036"
          y1="13.259"
          y2="13.259"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDECF7"></stop>
          <stop offset="1" stopColor="#C5E2F8"></stop>
        </linearGradient>
        <linearGradient
          id="paint19_linear_399_8703"
          x1="42.74"
          x2="42.74"
          y1="8.271"
          y2="10.771"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009AFF"></stop>
          <stop offset="1" stopColor="#08F"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SummaryBasket;
