import React, { useState } from "react";
import moment from "moment";
import QrModal from "./QrModal";
import { LazyImage } from "../../components";

const SimCardComp = ({ simData }) => {
  const [isModal, setisModal] = useState(false);

  const esim = simData.data;

  const isActive = simData?.usage?.sim?.status === "ACTIVE";

  return (
    <>
      <div className="w-full flex flex-col cursor-pointer bg-bg-2 hover:bg-bg-5 p-[24px] border border-border-2 rounded-[32px] space-y-3">
        <div className="flex items-center justify-between">
          <div className="border flex items-center justify-center border-bg-3 px-4 py-2 hover:bg-border-1 rounded-2xl">
            <p className="text-bg-3 text-xs font-bold">
              {isActive ? "Active" : "Purchased"}
            </p>
          </div>
          <p className="text-sm text-text-2 font-bold">
            {moment(esim.dateCreated).format("DD MMM YYYY")}
          </p>
        </div>

        <div className="flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <LazyImage
              src={simData?.country?.flagUrl || ""}
              alt={simData?.country?.name || "Country flag"}
              className="w-6 h-6 rounded-full"
            />
            <p className="font-bold text-base ml-4">
              {simData?.country?.name || ""}
            </p>
          </div>
          <p className="text-base mt-3 text-text-2 font-bold">
            {simData?.usage?.sim
              ? (simData?.usage?.sim?.consumedInBytes / 1000000000).toFixed(2)
              : 0}{" "}
            GB /{" "}
            <span className={simData?.usage?.sim && "text-black"}>
              {simData?.usage?.sim
                ? (simData?.usage?.sim?.size / 1000000000).toFixed(2)
                : 0}{" "}
              GB
            </span>
          </p>
        </div>

        <div className="flex justify-between flex-row items-center">
          <div className="flex flex-row items-center">
            {simData?.usage?.sim?.highestDateExpiry ? (
              isActive ? (
                <>
                  Expiry Date:{" "}
                  <span className="font-bold">
                    {moment(simData?.usage?.sim?.highestDateExpiry)?.format(
                      "DD MMM YYYY"
                    )}
                  </span>
                </>
              ) : (
                <span className="font-bold">Pending</span>
              )
            ) : (
              <span className="font-bold">Expired</span>
            )}
          </div>

          <button
            onClick={() => {
              setisModal(true);
            }}
            className="px-4 py-2 rounded-2xl bg-bg-1"
          >
            <p className="text-sm font-bold">Install ESIM</p>
          </button>
        </div>
      </div>
      {isModal ? (
        <QrModal
          open={isModal}
          Data={esim}
          handleClose={(v) => setisModal(false)}
        />
      ) : null}
    </>
  );
};

export default SimCardComp;
