import React from "react";

const ShowDescription = ({ text }) => {
  return (
    <div className="mt-6">
      <span className="text-xl font-medium">{text}</span>
    </div>
  );
};

export default ShowDescription;
