import React, { useEffect } from "react";
import { FaqSection } from "../content/HomePage";
import globe from "../assets/images/globe.png";
import { AdsPoster, ValueSection } from "../content/About";
import { useDataStore } from "../store";
import { centerClass } from "../utils";
import { LazyImage } from "../components";

const About = () => {
  const { setFooter } = useDataStore();

  useEffect(() => {
    setFooter({
      isFooter: true,
      isFooterImage: true,
    });
  }, []);

  return (
    <div>
      <div
        className={`flex my-[50px] text-text-1 text-center space-y-8 flex-col items-center ${centerClass}`}
      >
        <span className="flex items-center p-[10px] text-[10px] md:text-[18px] border border-border-1 rounded-full w-max">
          <LazyImage src={globe} className="mr-3" />
          We craft for yours
        </span>

        <span className="text-[56px] md:text-[100px] leading-[100%] font-bold">
          Enjoy seamless connectivity every time you Roam With Simfree
        </span>

        <span className="text-base md:text-xl md:max-w-[50%] font-medium">
          We understand the importance of staying in touch, whether it's for
          business, travel, or simply keeping up with loved ones.
        </span>
      </div>

      <div id="aboutPoster">
        <AdsPoster />
      </div>

      <div id="aboutValue">
        <ValueSection />
      </div>

      <FaqSection />
    </div>
  );
};

export default About;
