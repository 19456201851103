import React from "react";
import iphone from "../../assets/images/HomeMobile.png";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { centerClass } from "../../utils";
import { LazyImage } from "../../components";

export default function Banner() {
  return (
    <div
      className={`${centerClass} my-[50px] md:my-[100px] md:flex justify-between`}
    >
      <div
        className={`flex text-text-1 text-center md:text-left space-y-8 flex-col w-full items-center md:items-start md:w-[60%]`}
      >
        <span className="flex p-[10px] text-[10px] md:text-[18px] border border-border-1 rounded-full w-max items-center gap-1 justify-center">
          <ArrowCircleRightOutlinedIcon />
          Experience
          <span className="text-text-2 mr-1 font-semibold">Lightning-fast</span>
          connectivity with Simfree
        </span>

        <span className="text-[56px] md:text-[100px] leading-[100%] font-bold">
          Enhance the quality of your business
        </span>

        <span className="text-2xl md:text-[32px] font-medium">
          Partner with us to enhance your offerings and grow your business.{" "}
        </span>
      </div>

      <div className="md:w-[30%] mt-4 md:mt-0 w-full items-center justify-center flex">
        <LazyImage src={iphone} alt="" className="!h-[580px] w-[280px]" />
      </div>
    </div>
  );
}
