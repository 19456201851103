import React from "react";

function Logo({ className = "" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="174"
      height="30"
      fill="none"
      viewBox="0 0 174 30"
      className={className}
    >
      <path
        fill="#1B2638"
        d="M0 9a9 9 0 019-9h35.262c.815 0 1.476.66 1.476 1.475v13.187a7.377 7.377 0 01-2.161 5.216l-7.961 7.961A7.377 7.377 0 0130.399 30H1.475C.661 30 0 29.34 0 28.525V9z"
      ></path>
      <path
        fill="#FED98F"
        d="M2.46 9.836a7.377 7.377 0 017.376-7.377h12.295v3.688a3.689 3.689 0 01-3.688 3.689H2.459zM2.46 20.164H22.13a7.377 7.377 0 01-7.377 7.377H2.46v-7.377zM23.607 2.459h19.672v7.377H27.295a3.689 3.689 0 01-3.688-3.689V2.46zM2.46 11.312h15.983A3.689 3.689 0 0122.13 15v3.689H9.836a7.377 7.377 0 01-7.377-7.377zM23.607 15a3.689 3.689 0 013.689-3.688h15.983a7.377 7.377 0 01-7.377 7.377H23.607V15zM23.607 20.164h9.836a7.377 7.377 0 01-7.377 7.377h-2.46v-7.377z"
      ></path>
      <path
        fill="#1B2638"
        d="M165.547 25.233c-5.437 0-9.487-3.94-9.487-9.45 0-5.145 3.686-9.34 9.049-9.34 5.4 0 8.757 3.903 8.757 8.902v1.46h-14.303c.401 3.065 2.737 5.217 5.948 5.217 2.189 0 4.159-.948 5.217-2.919l2.773 1.423c-1.569 3.065-4.451 4.707-7.954 4.707zm-5.838-10.764h10.545c-.146-2.845-2.226-4.78-5.181-4.78-3.029 0-4.999 2.117-5.364 4.78zM145.928 25.233c-5.436 0-9.486-3.94-9.486-9.45 0-5.145 3.685-9.341 9.049-9.341 5.4 0 8.757 3.904 8.757 8.903v1.46h-14.304c.402 3.064 2.737 5.217 5.948 5.217 2.189 0 4.159-.948 5.218-2.919l2.773 1.423c-1.569 3.065-4.452 4.707-7.955 4.707zm-5.838-10.764h10.545c-.146-2.846-2.225-4.78-5.181-4.78-3.028 0-4.999 2.117-5.364 4.78zM129.547 24.832h-3.612V14.178c0-4.342 2.481-7.59 7.152-7.59h3.21v3.467h-2.882c-2.408 0-3.868 1.642-3.868 4.269v10.508z"
      ></path>
      <path
        fill="#1B2638"
        fillRule="evenodd"
        d="M114.721 7.735V7.59c0-4.342 2.481-7.589 7.152-7.589h3.211v3.466h-2.883c-2.408 0-3.868 1.642-3.868 4.27h6.275v3.137h-6.276v13.959h-3.612V10.873h-3.394V7.735h3.395z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#1B2638"
        d="M101.528 6.442c5.4 0 8.319 4.014 8.319 8.976v9.414h-3.612v-8.83c0-3.247-1.605-6.094-4.962-6.094-3.247 0-4.89 2.737-4.89 6.02v8.904h-3.612v-8.903c0-3.284-1.642-6.02-4.962-6.02-3.357 0-4.853 2.845-4.853 6.093v8.83h-3.612v-9.378c0-4.998 2.846-9.012 8.21-9.012 3.575 0 6.166 1.825 7.078 4.598.985-2.773 3.503-4.598 6.896-4.598zM72.308 7.713h3.613v17.12h-3.613V7.712zM72.308 1.464h3.613v3.46h-3.613v-3.46zM62.391 25.233c-3.328 0-5.888-1.316-7.278-3.986l2.78-1.537c.914 1.793 2.377 2.451 4.352 2.451 2.048 0 3.621-.841 3.621-2.414 0-3.767-10.278-.914-10.278-7.754 0-3.146 2.78-5.45 6.694-5.45 3.328 0 5.596 1.536 6.73 3.621l-2.78 1.573c-.768-1.573-2.231-2.122-3.877-2.122-1.683 0-3.146.878-3.146 2.305 0 3.657 10.278.987 10.278 7.754 0 3.402-3.292 5.56-7.096 5.56z"
      ></path>
    </svg>
  );
}

export default Logo;
