import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/FirebaseConfig";

export const handlePromoCode = async (uid, code) => {
  try {
    const userPromotionDoc = doc(db, "promotions", code);
    const userPromotionData = (await getDoc(userPromotionDoc))?.data();
    if (!userPromotionData) {
      throw new Error("Invalid Code");
    }

    if (userPromotionData.creatorId === uid) {
      throw new Error("User can't apply own code");
    }

    const userDoc = doc(db, "users", uid);
    const userData = (await getDoc(userDoc))?.data();
    if (
      userData &&
      userData.appliedVouchers &&
      userData.appliedVouchers.includes(code)
    ) {
      throw new Error("Code already applied");
    }

    return userPromotionData;
  } catch (error) {
    throw error;
  }
};
