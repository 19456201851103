import React, { useEffect, useState } from "react";
import { ButtonComp, InputComp } from "../components";
import SuccesForget from "../components/SuccesForget";
import { resetPassword } from "../services/FirebaseAuthManager";
import { useDataStore } from "../store";
import { centerClass, emailRegex } from "../utils";

export default function ForgotPswrd() {
  const [email, setEmail] = useState("");
  const [Success, setSuccess] = useState(false);
  const [loader, setLoader] = useState(false);

  const { setFooter } = useDataStore();

  useEffect(() => {
    setFooter({
      isFooter: true,
      isFooterImage: false,
    });
  }, []);

  const handleClick = () => {
    setLoader(true);
    resetPassword(email, (res, success) => {
      if (success) {
        setSuccess(true);
      } else {
        console.log("res", res);
      }
      setLoader(false);
    });
  };

  return (
    <>
      {Success ? (
        <>
          <SuccesForget />
        </>
      ) : (
        <div
          className={`my-[50px] md:my-[100px] space-y-4 ${centerClass} md:w-[400px] text-center md:text-left text-text-9 flex justify-center items-center flex-col`}
        >
          <p className="text-[32px] font-bold leading-8 text-text-1">
            Forgot Password
          </p>
          <p className="text-[16px] font-medium leading-5 text-center text-black">
            We’ll get this fixed no time. Enter the email address tou used for
            sign up, and we send you a link to reset your password
          </p>
          <div className="w-[100%] space-y-3">
            <InputComp
              type={"email"}
              placeholder={"Email"}
              handleChange={(value) => setEmail(value)}
            />
            <ButtonComp
              className="w-[100%]"
              text={"Send Email"}
              handleClick={handleClick}
              loader={loader}
              disabled={!emailRegex.test(email) || loader}
            />
          </div>
        </div>
      )}
    </>
  );
}
