import React from "react";

const LogoutIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    className={className}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M10 3h9a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-9M13 16l4-4m0 0l-4-4m4 4H4"
    />
  </svg>
);

export default LogoutIcon;
