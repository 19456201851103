import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthStore } from "../store";

const ProtectedRoute = ({ component: Component }) => {
  const { user } = useAuthStore();

  return user ? <Component /> : <Navigate to="/signin" />
};

export default ProtectedRoute;
