import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const InputComp = ({ type = "text", placeholder, handleChange }) => {
  const [inputValue, setInputValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    handleChange(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShow) => !prevShow);
  };

  return (
    <div
      className={`w-full flex items-center justify-between px-6 py-5 rounded-2xl border ${
        isFocused ? "borderLinear" : "border-border-1"
      }`}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      <input
        type={showPassword ? "text" : type}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        className="w-full outline-none bg-transparent text-base font-medium placeholder:text-text-7"
      />
      {type == "password" && (
        <div onClick={togglePasswordVisibility} className="cursor-pointer ml-2">
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </div>
      )}
    </div>
  );
};

export default InputComp;
