import React from "react";

function HomeIcon({ className = "w-6 h-6" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      className={className}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 10.565c0-.574 0-.861.074-1.126a2 2 0 01.318-.65c.163-.22.39-.397.843-.75l6.783-5.275c.351-.273.527-.41.72-.462a1 1 0 01.523 0c.194.052.37.189.721.462l6.783 5.275c.453.353.68.53.843.75.145.195.252.416.318.65.074.265.074.552.074 1.126V17.8c0 1.12 0 1.68-.218 2.108a2 2 0 01-.874.874C19.48 21 18.92 21 17.8 21H6.2c-1.12 0-1.68 0-2.108-.218a2 2 0 01-.874-.874C3 19.48 3 18.92 3 17.8v-7.235z"
      ></path>
      {/* <defs>
        <linearGradient
          id="paint0_linear_130_5415"
          x1="12"
          x2="12"
          y1="2.267"
          y2="21"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009AFF"></stop>
          <stop offset="1" stopColor="#08F"></stop>
        </linearGradient>
      </defs> */}
    </svg>
  );
}

export default HomeIcon;
