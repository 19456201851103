import React, { useState } from "react";
import { Modal } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase/FirebaseConfig";
import InputComp from "./InputComp";
import ButtonComp from "./ButtonComp";
import { Close } from "@mui/icons-material";

const NotificationModal = ({ open, onClose }) => {
  const [successModal, setSuccessModal] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [loader, setLoader] = useState(false);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");

  const handleClose = () => {
    onClose();
    setSuccessModal(false);
  };

  const handleSendNotifications = async () => {
    try {
      setErrMsg(false);
      setLoader(true);
      const sendNotifications = await httpsCallable(
        functions,
        "sendNotifications"
      )({
        title: title,
        description: desc,
      });

      setSuccessModal(true);
      setLoader(false);
    } catch (error) {
      setSuccessModal(false);
      setLoader(false);
      setErrMsg(true);
      console.error("Error sending notifications:", error);
    }
  };

  return (
    <>
      <Modal open={successModal} onClose={handleClose}>
        <div className="w-full h-full flex justify-center items-center">
          <div className="w-[90%] md:w-[500px] flex flex-col space-y-4 bg-white p-8 rounded-3xl">
            <span className="text-center text-2xl font-semibold">
              Notifications Sent
            </span>
            <span className="text-center">
              Notifications have been sent successfully to all users with
              notification enabled.
            </span>
            <ButtonComp text={"Done"} handleClick={handleClose} />
          </div>
        </div>
      </Modal>

      <Modal open={open && !successModal} onClose={handleClose}>
        <div className="w-full h-full flex justify-center items-center">
          <div className="w-[90%] md:w-[500px] space-y-4 bg-white p-8 rounded-3xl">
            <div className="flex justify-between items-center">
              <span className="text-xl font-semibold">Notification</span>
              <span className="cursor-pointer" onClick={handleClose}>
                <Close />
              </span>
            </div>
            <InputComp
              placeholder={"Title"}
              handleChange={(value) => setTitle(value)}
            />
            <InputComp
              placeholder={"Description"}
              handleChange={(value) => setDesc(value)}
            />

            {errMsg && (
              <span className="text-text-3">
                Error sending notifications. Please try again.
              </span>
            )}

            <ButtonComp
              text={"Send Notifications"}
              handleClick={handleSendNotifications}
              disabled={!title || !desc || loader}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NotificationModal;
