import React, { useState } from "react";
import { Search } from "@mui/icons-material";

const SearchInput = ({
  placeholder,
  handleChange,
  className = "",
  inputClass = "",
  icon,
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    handleChange?.(e.target.value);
  };

  return (
    <div
      className={`w-[40%] h-16 flex items-center px-6 justify-between rounded-full border border-border-1 ${className}`}
    >
      <input
        type="text"
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        className={`w-full outline-none bg-transparent text-xl font-semibold placeholder:text-text-7 ${inputClass}`}
      />
      {icon ? (
        icon
      ) : (
        <div className="ml-2 linearBg px-4 py-2 rounded-full text-white">
          <Search />
        </div>
      )}
    </div>
  );
};

export default SearchInput;
