import { Close } from "@mui/icons-material";
import { Modal } from "@mui/material";
import React from "react";
import { usePersistData } from "../../store";
import { ButtonComp, Icon, LazyImage, SearchInput } from "../../components";
import { useNavigate } from "react-router-dom";

const OrderSummaryModal = ({
  open,
  handleClose,
  handlePromoCode,
  codeSuccess,
  setPromoCode,
  handleClick,
  stripeData,
}) => {
  const navigate = useNavigate();
  const { cart, country } = usePersistData();

  const handleCodeChange = (v) => {
    setPromoCode(v);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="w-full h-full flex justify-center items-center text-text-1">
        <div className="w-[90%] md:w-[500px] bg-white p-6 rounded-[32px]">
          <div className="flex items-center justify-between my-4">
            <span className="font-semibold text-2xl leading-[100%]">
              Order Summary
            </span>
            <span onClick={handleClose} className="cursor-pointer">
              <Close />
            </span>
          </div>

          <div className="space-y-6 flex flex-col">
            <div className="w-full border border-border-2 rounded-3xl p-6 bg-bg-2 space-y-4">
              <div className="flex justify-between items-center">
                <span className="flex items-center text-text-1 text-base font-semibold">
                  <LazyImage
                    src={country.flagUrl}
                    alt={country.name}
                    className="w-6 h-6 mr-2 object-cover rounded-full"
                  />
                  {country.name}
                </span>
              </div>

              <div className="flex justify-between items-center">
                <span className="flex items-center text-text-1 text-base font-semibold space-x-2">
                  <Icon iconName="network" className={`h-6 w-6 text-black`} />
                  <span className="font-semibold text-2xl">{cart.size} GB</span>
                  <span className="text-sm font-medium">
                    | {cart.days} days
                  </span>
                </span>
                <span className="font-semibold text-2xl">${cart.price}</span>
              </div>

              <p className="text-xl font-semibold">Apply Code</p>
              <SearchInput
                placeholder="Refferal Code"
                handleChange={handleCodeChange}
                className="w-full bg-white !rounded-3xl"
                inputClass="!text-base !font-medium"
                icon={
                  <span
                    className="cursor-pointer text-sm font-semibold hover:text-text-2"
                    onClick={handlePromoCode}
                  >
                    Apply
                  </span>
                }
              />

              {codeSuccess && (
                <div
                  className={`flex items-center rounded-md ${
                    codeSuccess?.isSuccess ? "text-text-5" : "text-text-11"
                  }`}
                >
                  <span className="font-medium text-base">
                    {codeSuccess?.message}
                  </span>
                </div>
              )}

              {stripeData?.discount > 0 && (
                <div className="flex items-center justify-between">
                  <span className="text-2xl text-text-9 font-semibold">
                    {stripeData?.isReward ? "Reward" : "Discount"}
                  </span>
                  <span className="text-2xl text-text-11 font-semibold">
                    - ${stripeData.discount}
                  </span>
                </div>
              )}

              <div className="flex items-center justify-between">
                <span className="text-2xl text-text-9 font-semibold">
                  Total
                </span>
                <span className="text-2xl text-text-9 font-semibold">
                  ${stripeData?.amount || 0}
                </span>
              </div>
            </div>

            <p className="text-base font-semibold text-text-8">
              Activation Policy
            </p>
            <p className="text-text-7 text-sm font-medium !mt-2">
              The validity periods starts when the SIM connects to any supported
              networks
            </p>

            <ButtonComp
              text={`Pay - $${stripeData?.amount || 0}`}
              handleClick={handleClick}
              className="w-full"
            />

            <div className="flex justify-center space-x-2">
              <p
                onClick={() => {
                  navigate("/terms&conditions");
                }}
                className="text-base cursor-pointer font-sm underline text-text-10 hover:text-bg-1"
              >
                Terms of Service
              </p>
              <p className="text-base font-sm text-text-10"> & </p>
              <p
                onClick={() => {
                  navigate("/privacyPolicy");
                }}
                className="text-base cursor-pointer font-sm underline text-text-10 hover:text-bg-1"
              >
                Privacy Policy
              </p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OrderSummaryModal;
