import React, { useEffect } from "react";
import { centerClass } from "../utils";
import { useDataStore } from "../store";

const PrivacyPolicy = () => {
  const { setFooter } = useDataStore();

  useEffect(() => {
    setFooter({
      isFooter: true,
      isFooterImage: true,
    });
  }, []);
  return (
    <div className={`${centerClass} md:w-[90%] w-full`}>
      <main className="container mx-auto px-4 py-8">
        <span className="flex w-full justify-center items-center">
          <h1 className="text-4xl text-text-2 font-bold mb-6">
            Privacy Policy
          </h1>
        </span>

        <section className="mb-8">
          <h3 className="text-2xl font-semibold mb-4">1. Introduction</h3>
          <p className="mb-4">
            Welcome to SimFree. This Privacy Policy explains how we collect,
            use, disclose, and safeguard your information when you visit our
            website. By using our website, you consent to the practices
            described in this policy.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-2xl font-semibold mb-4">
            2. Information We Collect
          </h3>
          <p className="mb-4">
            We may collect various types of information about you, including:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>
              <strong>Personal Information:</strong> Such as your name, email
              address, and contact details, which you provide when you register
              or interact with us.
            </li>
            <li>
              <strong>Usage Data:</strong> Information about how you use our
              website, including your IP address, browser type, and pages
              visited.
            </li>
            <li>
              <strong>Cookies:</strong> Small files placed on your device that
              help us understand your preferences and improve your experience.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h3 className="text-2xl font-semibold mb-4">
            3. How We Use Your Information
          </h3>
          <p className="mb-4">
            We use the information we collect for various purposes, including:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>
              <strong>To Provide and Improve Services:</strong> To enhance your
              experience on our website and ensure it meets your needs.
            </li>
            <li>
              <strong>To Communicate:</strong> To send you updates, newsletters,
              and other information relevant to your interests and interactions
              with us.
            </li>
            <li>
              <strong>For Analytics:</strong> To understand usage patterns and
              improve our website’s functionality and content.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h3 className="text-2xl font-semibold mb-4">
            4. How We Share Your Information
          </h3>
          <p className="mb-4">
            We do not sell or rent your personal information to third parties.
            However, we may share your information with:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>
              <strong>Service Providers:</strong> Third-party vendors who assist
              us in operating our website and providing services, under strict
              confidentiality agreements.
            </li>
            <li>
              <strong>Legal Requirements:</strong> When required by law, or to
              respond to legal requests and enforce our policies.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h3 className="text-2xl font-semibold mb-4">5. Data Security</h3>
          <p className="mb-4">
            We implement a range of security measures to protect your personal
            information from unauthorized access, disclosure, alteration, and
            destruction. However, no method of transmission over the internet or
            electronic storage is completely secure, and we cannot guarantee
            absolute security.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-2xl font-semibold mb-4">6. Your Rights</h3>
          <p className="mb-4">
            Depending on your location, you may have certain rights regarding
            your personal data, including:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>
              <strong>Access:</strong> The right to request a copy of the
              personal data we hold about you.
            </li>
            <li>
              <strong>Correction:</strong> The right to request corrections to
              any inaccurate or incomplete data.
            </li>
            <li>
              <strong>Deletion:</strong> The right to request that we delete
              your personal data under certain conditions.
            </li>
          </ul>
          <p className="mb-4">
            To exercise these rights, please contact us using the information
            provided in the “Contact Us” section.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-2xl font-semibold mb-4">
            7. Changes to This Privacy Policy
          </h3>
          <p className="mb-4">
            We may update this Privacy Policy from time to time. Any changes
            will be posted on this page with an updated effective date. We
            encourage you to review this policy periodically to stay informed
            about how we are protecting your information.
          </p>
        </section>

        <section className="">
          <h3 className="text-2xl font-semibold mb-4">8. Contact Us</h3>
          <p className="mb-4">
            If you have any questions or concerns about this Privacy Policy or
            our data practices, please contact us at support@simfree.com. We
            will do our best to address your inquiries and resolve any issues.
          </p>
        </section>
      </main>
    </div>
  );
};

export default PrivacyPolicy;
