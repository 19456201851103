import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db, functions } from "../firebase/FirebaseConfig";
import { CircularProgress } from "@mui/material";
import { LazyImage, StripeCardModal } from "../components";
import { FaqSection, Features } from "../content/HomePage";
import { useAuthStore, useDataStore, usePersistData } from "../store";
import { httpsCallable } from "firebase/functions";
import {
  OrderSummaryModal,
  ShowPlans,
  SelectPayModal,
  Details,
  SuccessModal,
} from "../content/Destination";
import { handlePromoCode } from "../services/FirebaseStoreManager";
import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  onSnapshot,
  deleteDoc,
} from "firebase/firestore";
import { centerClass } from "../utils";

const DestinationDetails = () => {
  const navigate = useNavigate();

  const { user } = useAuthStore();
  const { setFooter } = useDataStore();
  const { cart, setCart, country } = usePersistData();

  const [plans, setPlans] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [stripeModal, setStripeModal] = useState(false);
  const [summaryModal, setSummaryModal] = useState(false);
  const [payMethod, setPayMethod] = useState(false);
  const [stripeData, setStripeData] = useState(null);
  const [selectedComp, setSelectedComp] = useState(null);
  const [isSuccess, setSuccess] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeData, setPromoCodeData] = useState(null);
  const [codeSuccess, setCodeSuccess] = useState(null);

  useEffect(() => {
    const uid = user?.id;

    const contactsCollectionRef = collection(
      db,
      "users/" + uid + "/cryptoEvents"
    );
    const q = query(
      contactsCollectionRef,
      orderBy("createdAt", "desc"),
      limit(1)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const latestDoc = snapshot.docs[0];

      if (latestDoc) {
        setCart(null);
        setSuccess(true);
        setTimeout(() => {
          navigate("/myesim");
        }, 3000);
        deleteDoc(latestDoc.ref)
          .then(() => {
            console.log("Document successfully deleted!");
          })
          .catch((error) => {
            console.error("Error deleting document:", error);
          });
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    setFooter({
      isFooter: true,
      isFooterImage: true,
    });

    getDocs(collection(db, "plans/" + country?.region + "/plans"))
      .then((res) => {
        const plans = res.docs.map((doc) => doc.data());
        setPlans(plans);
        setSelectedComp(<ShowPlans plans={plans} />);
        setLoading(false);
      })
      .catch((e) => console.error("error fetching plans", e));
  }, []);

  useEffect(() => {
    if (country) {
      if (!cart) {
        setStripeData(null);
        return;
      }
      fetchPaymentSheetParams();
    }
  }, [cart, promoCodeData]);

  const fetchPaymentSheetParams = async () => {
    try {
      let obj = {
        iso: country.code,
        region: country.region,
        planID: cart.id,
        channel: "web",
        ...(country.esimID && { esimID: country.esimID }),
        ...(promoCodeData && { ...promoCodeData }),
      };

      const response = await httpsCallable(functions, "checkout")(obj);

      setStripeData(response.data);

      return response.data;
    } catch (error) {
      setStripeData(null);
      console.error("Error fetching payment sheet params:", error);
    }
  };

  const handleApplyPromoCode = async () => {
    try {
      const res = await handlePromoCode(user.id, promoCode);

      const { validity, type, receiver, code } = res;

      if (validity.toDate() > new Date()) {
        setPromoCodeData({
          discountType: type,
          discount: receiver,
          validity: validity.toDate(),
          promoCode: code,
        });

        setCodeSuccess({
          isSuccess: true,
          message: `Code successfully applied`,
        });
      } else {
        setPromoCodeData(null);
        setCodeSuccess(null);
      }
    } catch (error) {
      setPromoCodeData(null);
      setCodeSuccess({
        isSuccess: false,
        message: `Enter valid promo code`,
      });
      console.error(`Promo Code Error ${error}`);
    }
  };

  const handleGetESim = () => {
    if (!user) {
      navigate("/signin");
      return;
    }
    setSummaryModal(true);
  };

  if (!country) {
    navigate("/destinations");
    return;
  }

  return isLoading ? (
    <div className="flex justify-center items-center h-screen">
      <CircularProgress />
    </div>
  ) : (
    <>
      <StripeCardModal
        open={stripeModal}
        handleClose={() => setStripeModal(false)}
        stripeData={stripeData}
        setSuccess={setSuccess}
      />

      {summaryModal && (
        <OrderSummaryModal
          open={summaryModal}
          handleClose={(v) => {
            setSummaryModal(false);

            setPromoCodeData(null);
            setCodeSuccess(null);
            setPromoCode("");
          }}
          handleClick={() => {
            setSummaryModal(false);
            setPayMethod(true);
          }}
          codeSuccess={codeSuccess}
          stripeData={stripeData}
          handlePromoCode={handleApplyPromoCode}
          setPromoCode={setPromoCode}
        />
      )}

      <SelectPayModal
        open={payMethod}
        handleClose={() => setPayMethod(false)}
        metadata={stripeData?.metadata}
        setStripeModal={setStripeModal}
      />

      <SuccessModal
        open={isSuccess}
        handleClose={() => {
          setSuccess(false);
        }}
      />

      <div className={`${centerClass} relative`}>
        <span className="absolute text-center inset-0 flex items-center justify-center text-white text-[56px] md:text-[120px] leading-[100%] font-bold">
          {country.name}
        </span>
        <LazyImage
          src={country.thumbnail}
          alt={country.name}
          className="w-full object-cover rounded-[28px] h-[250px] md:h-[580px] overflow-hidden"
        />
      </div>

      <Details
        plans={plans}
        selectedComp={selectedComp}
        setSelectedComp={setSelectedComp}
        handleGetESim={handleGetESim}
      />

      <Features />

      <FaqSection />
    </>
  );
};

export default DestinationDetails;
