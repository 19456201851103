import { loadStripe } from "@stripe/stripe-js";
import {
  test1,
  test2,
  test3,
  test4,
  test5,
  test6,
  test7,
} from "../assets/images";

export const centerClass = "w-[90%] mx-auto max-w-[1440px]";
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
);

export const layoutDataArr = [
  {
    name: "eSIM Solutions",
    icon: "HomeIcon",
    iconClasses: "h-6 w-6 linearText",
    links: [
      {
        name: "Get Started",
        isScreen: true,
        screen: "",
        moveId: "getStarted",
      },
      {
        name: "Explore Plans",
        isScreen: true,
        screen: "destinations",
        moveId: "explorer",
      },
    ],
    navigate: "/",
  },
  {
    name: "Destionations",
    icon: "PackageIcon",
    iconClasses: "h-6 w-6 text-text-3",
    links: [
      {
        name: "Top Travel Destinations",
        isScreen: true,
        screen: "destinations",
        moveId: "popularCountries",
      },
      {
        name: "Common Places",
        isScreen: true,
        screen: "destinations",
        moveId: "explorer",
      },
    ],
    navigate: "/destinations",
  },
  {
    name: "Support Center",
    icon: "OrgIcon",
    iconClasses: "h-6 w-6 text-text-4",
    links: [
      {
        name: "Device Compatibility",
        isScreen: true,
        screen: "about",
        moveId: "",
      },
      {
        name: "FAQs",
        isScreen: true,
        screen: "",
        moveId: "faqSection",
      },
    ],
    navigate: "/about",
  },
  {
    name: "About",
    icon: "HelpIcon",
    iconClasses: "h-6 w-6 text-text-5",
    links: [
      {
        name: "Company Overview (Mission, Vision)",
        isScreen: true,
        screen: "about",
        moveId: "aboutPoster",
      },
      {
        name: "Our Goals & Values",
        isScreen: true,
        screen: "about",
        moveId: "aboutValue",
      },
    ],
    navigate: "/about",
  },
  {
    name: "Business Partnerships",
    icon: "HelpIcon",
    iconClasses: "h-6 w-6 text-text-5",
    links: [
      {
        name: "Telecom Providers",
        isScreen: true,
        screen: "review",
        moveId: "",
      },
      {
        name: "Influencer Collaborations",
        isScreen: true,
        screen: "review",
        moveId: "",
      },
    ],
    navigate: "/review",
  },
  {
    name: "Career",
    icon: "HelpIcon",
    iconClasses: "h-6 w-6 text-text-5",
    links: [
      {
        name: "Join Our Team",
        isScreen: true,
        screen: "career",
        moveId: "",
      },
    ],
    navigate: "/career",
  },
];

export const faqApplication = [
  {
    ques: "How do I download the SimFree mobile app?",
    desc: `Download it from the Apple App Store or Google Play Store by searching for "SimFree."`,
  },
  {
    ques: "How do I manage my account using the SimFree mobile app?",
    desc: `Log in to view account details, check usage, change plans, and manage payments.`,
  },
  {
    ques: "What features does the SimFree mobile app offer?",
    desc: `The app makes travel and connectivity easier with instant activation and plan switching. Users can track data usage in real-time and choose from various data plans for trips, whether short or long.`,
  },
  // {
  //   ques: "Is the SimFree app available in multiple languages?",
  //   desc: `Yes, the app supports multiple languages. You can change the language in the app settings.`,
  // },
];

export const faqPayment = [
  {
    ques: "What payment methods are accepted?",
    desc: `We accept Visa, MasterCard, and popular cryptocurrencies like Bitcoin (BTC), Tether (USDT), Ethereum (ETH), and Sonera.`,
  },
  {
    ques: "How do I make a payment?",
    desc: `Choose your destination, pick your preferred plan, and at  checkout,  select the payment method to complete your purchase.`,
  },
  {
    ques: "Can I set up automatic payments?",
    desc: `Yes, you can set up automatic payments using your credit card or cryptocurrency wallet in the billing section of your account.`,
  },
  {
    ques: "Is it safe to pay with cryptocurrency?",
    desc: `Yes, our payment system is secure and supports popular cryptocurrencies, ensuring a safe transaction process.`,
  },
  {
    ques: "How do I know my payment was successful?",
    desc: `A success message will display on your screen upon completing the transaction and automatically redirect you to the Esim feature.`,
  },
];

export const testimonials = [
  {
    name: "John D., USA",
    image: test1,
    desc: `"SimFree made switching my phone plan a breeze. The customer service was excellent, guiding me through every step. I saved money and experienced no disruptions in service. The best part was how transparent the pricing was—no hidden fees!"`,
  },
  {
    name: "Emma L., UK",
    image: test2,
    desc: `"As a frequent traveller, SimFree has been a game-changer. Their international plans are affordable and easy to activate. The website is intuitive, making it simple to manage my account from anywhere in the world."`,
  },
  {
    name: "Carlos M., Spain",
    image: test3,
    desc: `"I appreciate the clear pricing and easy setup with SimFree. The customer service team answered all my questions and helped me choose the best plan. I've never had such a hassle-free experience with a mobile service provider."`,
  },
  {
    name: "Sophie K., Germany",
    image: test4,
    desc: `"Switching to SimFree was seamless. I was worried about losing service during the transition, but it was smooth and quick. Their plans are flexible, allowing me to adjust based on my usage. Highly recommended for its convenience and affordability."`,
  },
  {
    name: "Ahmed H., Dubai",
    image: test5,
    desc: `"SimFree's service is top-notch. The international plans are perfect for my travel needs, offering excellent coverage and competitive rates. The support team is always ready to help, making my experience even better."`,
  },
  {
    name: "Marie T., France",
    image: test6,
    desc: `"Great experience with SimFree. The website is user-friendly, and the support team is very helpful. I was able to switch plans easily and find one that suits my needs perfectly. Highly recommend their service."`,
  },
  {
    name: "Luca R., Italy",
    image: test7,
    desc: `"SimFree offers excellent value for money. Their plans are flexible and easy to manage through their intuitive website. I've had no issues with connectivity, and the customer support is fantastic."`,
  },
  {
    name: "Anna S., Sweden",
    image: test6,
    desc: `"I love how simple it is to change plans with SimFree. It's perfect for my busy lifestyle. The process is quick, and the support team is always available to assist with any questions or concerns."`,
  },
  {
    name: "Nina P., Norway",
    image: test5,
    desc: `"SimFree's customer service is exceptional. They resolved my issue quickly and efficiently. The website is easy to navigate, and the plans are transparent with no hidden fees. I'm very satisfied with their service."`,
  },
  {
    name: "David B., Australia",
    image: test4,
    desc: `"Using SimFree has been a fantastic experience. The savings are significant, and the service is reliable. I travel frequently, and their international plans are a lifesaver, offering great coverage wherever I go."`,
  },
  {
    name: "Javier G., Mexico",
    image: test3,
    desc: `"SimFree is the best option for international travellers. The plans are affordable, and the setup process is straightforward. I appreciate the clear instructions and helpful customer support."`,
  },
  {
    name: "Isabella C., Brazil",
    image: test2,
    desc: `"I'm very satisfied with SimFree. The pricing is clear, and the service is excellent. I've had no connectivity issues, and switching plans was a breeze. Highly recommend their service."`,
  },
  {
    name: "Oliver J., Canada",
    image: test1,
    desc: `"SimFree made it easy to switch providers. The whole process was straightforward and hassle-free. The website is user-friendly, and the support team is knowledgeable and responsive."`,
  },
  {
    name: "Liam W., New Zealand",
    image: test7,
    desc: `"Great service from SimFree. The plans are affordable, and the coverage is excellent. I had a few questions during setup, and the customer support team was very helpful and quick to respond."`,
  },
  {
    name: "Elena R., Russia",
    image: test6,
    desc: `"SimFree's service exceeded my expectations. The support team is friendly and efficient, always ready to help with any issues. The plans are flexible, and the website is easy to use."`,
  },
  {
    name: "Yusuf A., Turkey",
    image: test5,
    desc: `"I'm impressed with SimFree. The website is easy to navigate, and the service is reliable. I was able to switch plans quickly and find one that fits my needs perfectly."`,
  },
  {
    name: "Hiroshi M., Japan",
    image: test4,
    desc: `"SimFree is perfect for frequent travellers. The international plans are affordable and easy to manage. The website is intuitive, and the customer support is always available to assist."`,
  },
  {
    name: "Chen L., China",
    image: test3,
    desc: `"Switching to SimFree was a great decision. The customer support is excellent and very responsive. The plans are transparent, and the website is easy to navigate. Highly recommend their service."`,
  },
  {
    name: "Amelia P., South Africa",
    image: test2,
    desc: `"SimFree offers great value and excellent customer service. The plans are flexible, and the website is user-friendly. I had no issues during the transition, and the support team was very helpful."`,
  },
  {
    name: "Sophia K., South Korea",
    image: test1,
    desc: `"Using SimFree has been a great experience. The plans are flexible, and the service is reliable. The website is easy to navigate, and the customer support is always available to assist with any questions."`,
  },
];

export const playStoreLink = "https://play.google.com/store/";
export const appleStoreLink = "https://apps.apple.com/us/";
export const careerLink =
  "https://almond-reaper-2f5.notion.site/Simfree-561dbc41e64a46de96fab2b306e6fc86";

export const openLinkInNewTab = (url) => {
  window.open(url, "_blank", "noopener,noreferrer");
};
