import React from "react";
import { Icon, LazyImage } from "../../components";
import AboutBanner from "../../assets/images/AboutBanner-Img.png";
import camera from "../../assets/images/Camera 1.png";
import card from "../../assets/images/Card 1.png";
import { centerClass } from "../../utils";

const ValueSection = () => {
  return (
    <div className="w-full flex justify-between">
      <div
        className={`${centerClass} flex justify-between flex-wrap border bg-border-2 mt-[50px] md:mt-[100px] p-6 rounded-3xl`}
      >
        <div className="w-full md:w-[60%] space-y-8">
          <div className="w-full gap-3 flex items-center">
            <Icon iconName="logoIcon" className={`h-6 w-6 linearText`} />
            <p className="text-xl font-medium linearText text-justify">Value</p>
          </div>
          <div className="text-6xl font-bold text-black">The Core Value</div>

          <div className="text-xl font-medium text-black text-justify">
            We believe in providing top-notch eSIM solutions that empower you to
            stay connected anytime and anywhere.
          </div>

          <div className="md:flex justify-between">
            <div className="w-full md:w-[47%] font-medium text-black text-justify">
              <LazyImage src={camera} />
              <p className="mt-5">
                Our mission is simple: to make connectivity effortless. We
                envision a world where individuals can effortlessly connect with
                anyone, anywhere, anytime.
              </p>
            </div>

            <div className="w-full md:w-[47%] font-medium text-black text-justify">
              <LazyImage src={card} />
              <p className="mt-5">
                Our vision at Simfree is to revolutionise the way people
                experience mobile connectivity. By leveraging the power of eSIM
                technology, we're making this vision a reality.
              </p>
            </div>
          </div>
        </div>

        <div className="md:w-[35%] mt-4 md:mt-0 overflow-hidden rounded-2xl">
          <LazyImage
            className="transition-transform hover:scale-110"
            src={AboutBanner}
          />
        </div>
      </div>
    </div>
  );
};

export default ValueSection;
