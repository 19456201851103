import React, { useState } from "react";
import { ButtonComp, ModalComp } from "../../components";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase/FirebaseConfig";

const SelectPayModal = ({ open, handleClose, setStripeModal, metadata }) => {
  const [loading, setLoading] = useState(false);

  const handlePayment = async () => {
    setLoading(true);
    try {
      const createPayment = await httpsCallable(
        functions,
        "createCryptomusPayment"
      )(metadata);

      const url = createPayment?.data?.result?.url || "";

      if (url) {
        window.location.assign(url); // Redirect
      } else {
        alert("Something went wrong, please try again");
      }
      console.log("Payment created:", createPayment);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert("Something went wrong, please try again");
      console.error("Error creating payment:", error);
    }
    handleClose();
  };

  return (
    <ModalComp open={open}>
      <ButtonComp
        text={"Pay with Card"}
        className="text-base md:text-xl"
        handleClick={() => setStripeModal(true)}
        disabled={loading}
      />
      <ButtonComp
        text={"Pay with Crypto"}
        className="text-base md:text-xl"
        handleClick={handlePayment}
        disabled={loading}
      />
      <ButtonComp
        text={"Cancel"}
        className="text-base md:text-xl"
        handleClick={handleClose}
        disabled={loading}
      />
    </ModalComp>
  );
};

export default SelectPayModal;
