import React, { useEffect, useState } from "react";
import { centerClass } from "../../utils";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase/FirebaseConfig";
import { ButtonsComp, Icon, LazyImage, SliderComp } from "../../components";
import { Tooltip } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { usePersistData } from "../../store";

const TopCountries = () => {
  const [topCountries, setTopCountries] = useState([]);
  const [slidera, setSliderA] = useState(null);
  const [sliderb, setSliderB] = useState(null);

  const { setCountry, setCart } = usePersistData();

  const navigate = useNavigate();

  useEffect(() => {
    fetchPopular();
  }, []);

  const nextSlide = () => {
    slidera && slidera.slickNext();
    setTimeout(()=>{
      sliderb && sliderb.slickNext();
    }, 200)
  };

  const prevSlide = () => {
    slidera && slidera.slickPrev();
    setTimeout(()=>{
      sliderb && sliderb.slickPrev();
    }, 200)
  };

  const fetchPopular = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "popular"));
      const destinationsData = querySnapshot.docs.map((doc) => doc.data());

      const arr = destinationsData[0].countries;

      const countriesRef = collection(db, "countries-meta");

      const data = query(countriesRef, where("code", "in", arr));

      const docs = await getDocs(data);

      const popularCountries = docs.docs.map((doc) => doc.data());

      setTopCountries(popularCountries);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={`my-[50px] md:my-[100px] ${centerClass} overflow-hidden`}>
      <div className={`flex flex-col`}>
        <div className="flex items-center space-x-4 mb-6">
          <Icon iconName="logoIcon" className={`h-6 w-6 linearText`} />
          <span className="text-base md:text-xl linearText font-semibold">
            For Ultimate Wanderer
          </span>
        </div>{" "}
        <div className="flex justify-between">
          <span className="font-bold text-[30px] w-full md:w-[50%] md:text-[60px] leading-[100%]">
            Choose Your Next Travel Destination
          </span>
          <span className="md:block hidden">
            <ButtonsComp goToPrev={prevSlide} goToNext={nextSlide} />
          </span>
        </div>
      </div>

      {[1, 2].map((v, i) => {
        const divider = Math.floor(topCountries.length / 2);
        const arr =
          i === 0
            ? topCountries.slice(0, divider)
            : topCountries.slice(divider);

        return (
          <div
            className={`flex space-x-8 overflow-hidden w-full ${
              i === 1 ? "hidden md:flex" : "ml-0"
              // i === 1 ? "ml-10 hidden md:flex" : "ml-0"
            }`}
            key={i}
          >
            <SliderComp setSlider={i == 0 ? setSliderA : setSliderB}>
              {arr.map((item, index) => (
                <div
                  key={index}
                  className="px-4 cursor-pointer !w-[95%] text-text-1 pt-4 pb-5 rounded-[32px] relative md:h-[315px] bg-bg-2 border border-border-1 mt-4"
                  onClick={() => {
                    setCountry(item);
                    setCart(null);
                    navigate(`/checkout`);
                  }}
                >
                  <LazyImage
                    src={item.thumbnail}
                    alt={item.name}
                    className="w-full md:max-w-[400px] !h-[238px] rounded-3xl object-cover"
                  />
                  <div className="flex text-text-1 flex-row items-center justify-between p-2">
                    <Tooltip title={item.name} placement={"bottom"} arrow>
                      <span className="font-bold text-[32px] truncate">
                        {item.name}
                      </span>
                    </Tooltip>
                    <div className="items-center">
                      <span className="text-xs font-medium">
                        <ArrowForward
                          className="cursor-pointer"
                          sx={{
                            fontSize: 32,
                            color: "#343434",
                          }}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="absolute top-8 left-8">
                    <LazyImage
                      src={item.flagUrl}
                      alt={item.name}
                      className="w-[54px] h-[54px] object-cover rounded-full"
                    />
                  </div>
                </div>
              ))}
            </SliderComp>
          </div>
        );
      })}
      <span className={`my-4 block md:hidden`}>
        <ButtonsComp goToPrev={prevSlide} goToNext={nextSlide} />
      </span>
    </div>
  );
};

export default TopCountries;
