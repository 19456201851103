import React from "react";

const Profile = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    className={className}
  >
    <circle cx="12" cy="8" r="4" stroke="#000" strokeWidth="1.5" />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M4 20c0-4 8-6 8-6s8 2 8 6"
    />
  </svg>
);

export default Profile;
