import React, { useEffect, useState } from "react";
import { ButtonComp, InputComp } from "../components";
import { signUpEmailAndPassword } from "../services/FirebaseAuthManager";
import { useNavigate } from "react-router-dom";
import { useDataStore } from "../store";
import { centerClass } from "../utils";

const formArr = [
  {
    type: "email",
    placeholder: "Email",
  },
  {
    type: "password",
    placeholder: "Password",
  },
  {
    type: "confirmPass",
    placeholder: "Confirm Password",
  },
];

const SignUp = () => {
  const [userData, setUserData] = useState({
    email: "",
    password: "",
    confirmPass: "",
  });
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const { setFooter } = useDataStore();

  useEffect(() => {
    setFooter({
      isFooter: true,
      isFooterImage: false,
    });
  }, []);

  const handleSubmit = () => {
    if (userData.password !== userData.confirmPass) {
      setError("Password and Confirm Password do not match");
      return;
    }
    setLoader(true);

    signUpEmailAndPassword(userData, (data, success) => {
      if (success) {
        navigate("/checkout");
      } else {
        setError(data);
      }
      setLoader(false);
    });
  };

  return (
    <div
      className={`my-[50px] md:my-[100px] space-y-4 ${centerClass} md:w-[400px] text-center md:text-left text-text-9 flex justify-center items-center flex-col`}
    >
      <span className="text-[32px] font-bold">Welcome to FreeSIM</span>
      <span className="text-xl font-medium !mb-8">
        We’ll sign you in or create an account if you don’t have one yet
      </span>

      {formArr.map((item, index) => (
        <InputComp
          key={index}
          type={item.type === "confirmPass" ? "password" : item.type}
          placeholder={item.placeholder}
          handleChange={(value) =>
            setUserData({ ...userData, [item.type]: value })
          }
        />
      ))}

      <ButtonComp
        handleClick={handleSubmit}
        text="Sign Up"
        className="w-full"
        loader={loader}
        disabled={
          !userData.email ||
          !userData.password ||
          !userData.confirmPass ||
          loader
        }
      />

      {error && <div className="text-red-500 text-sm mb-4">{error}</div>}

      <span
        className="text-base !mt-10 font-semibold linearText cursor-pointer"
        onClick={() => navigate("/signin")}
      >
        Already have an account? Sign In
      </span>
      {/* <p
            className="text-base font-semibold linearText cursor-pointer"
            onClick={() => {}}
          >
            I forgot my password
          </p> */}
      <span
        className="text-base font-medium text-black  cursor-pointer"
        onClick={() => navigate("/signup")}
      >
        We will process your data as set forth in our
      </span>
      <div className="text-base font-medium linearText">
        <span
          className="underline cursor-pointer"
          onClick={() => navigate("/terms&conditions")}
        >
          Terms of Use
        </span>{" "}
        ,{" "}
        <span
          className="underline cursor-pointer"
          onClick={() => navigate("/privacyPolicy")}
        >
          Privacy Policy
        </span>{" "}
        and Data processing Agreement
      </div>
    </div>
  );
};

export default SignUp;
