import React from "react";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import { careerLink, centerClass } from "../../utils";

export default function Banner() {
  const jobs = [
    {
      title: "React Native Engineer",
      points: [
        "Mobile App Development",
        "UI/UX Design",
        "JavaScript Development",
      ],
    },
    {
      title: "MERN Stack Engineer",
      points: [
        "React.js Development",
        "Node.js Development",
        "Full Stack Development",
        "MongoDB Database",
      ],
    },
    {
      title: "Designer",
      points: ["UI/UX Design", "Graphic Design", "Visual Communication"],
    },
    {
      title: "Content Creator",
      points: ["Copywriting", "Social Media Management", "Content Strategy"],
    },
    {
      title: "Product Manager",
      points: ["Product Strategy", "Market Research", "Product Development"],
    },
    {
      title: "Salesperson",
      points: [
        "Sales Prospecting",
        "Customer Relationship Management",
        "Sales Presentations",
      ],
    },
  ];
  return (
    <div className={centerClass}>
      <div>
        <div className="w-full gap-3 flex items-center justify-center">
          <span className="items-center flex gap-2 bg-white text-[10px] md:text-[18px] font-medium p-[10px] h-[45px] border border-border-2 rounded-full">
            <PeopleOutlineOutlinedIcon className="linearText" /> Join with us
          </span>
        </div>
        <div className="text-[56px] md:text-[100px] font-bold text-text-1 mt-4 !text-center ">
          Join Our Team at Simfree!
        </div>
      </div>
      <div className="w-[full] min-h-[600px] flex justify-center  items-center">
        <div className="w-[90%] mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {jobs?.map((e, i) => {
            return (
              <div
                key={i}
                onClick={() => {
                  window.open(careerLink);
                }}
                className="w-full flex h-56 justify-between flex-col cursor-pointer bg-bg-2 hover:bg-bg-5 p-[24px] border border-border-2 rounded-[32px]"
              >
                <div className="flex w-[100%] justify-between text-[32px] font-bold text-text-1">
                  <span>{e.title}</span>
                  <span>
                    <ArrowForwardOutlinedIcon />{" "}
                  </span>
                </div>
                {/* 
                <span className="text-base text-text-1 font-medium mt-4 mb-5">
                  {e.job}
                </span> */}

                <div className="items-center flex space-x-2">
                  {e.points?.map((point, index) => {
                    if (index > 1) return null;
                    return (
                      <span
                        key={index}
                        className="text-sm bg-white rounded-xl p-[10px] text-text-1 font-medium leading-6 truncate w-max"
                      >
                        {point}
                      </span>
                    );
                  })}
                  {e.points.length > 2 && (
                    <span className="text-sm bg-white rounded-xl p-[10px] text-text-1 font-medium leading-6">
                      +{e.points.length - 2}
                    </span>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
