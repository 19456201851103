import React from 'react';
import LazyLoad from 'react-lazyload';

const LazyImage = ({ src, className, alt }) => {

  return (
    <LazyLoad height={200} offset={100} once>
      <img src={src} alt={alt || "Image"} className={className}/>
    </LazyLoad>
  );
}

export default LazyImage