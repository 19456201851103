import { create } from "zustand";

const useAuthStore = create((set) => ({
  user: null,
  updateUser: (userData) =>
    set((state) => ({
      user: userData,
    })),
}));

export default useAuthStore;
