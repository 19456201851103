// store.js
import { create } from "zustand";
import { persist } from "zustand/middleware";

const usePersistData = create(
  persist(
    (set) => ({
      cart: null,
      country: null,
      setCart: (data) =>
        set((state) => ({
          cart: data,
        })),
      setCountry: (data) =>
        set((state) => ({
          country: data,
        })),
    }),
    {
      name: "simfree-store",
      getStorage: () => localStorage, // (optional) by default, 'localStorage' is used
    }
  )
);

export default usePersistData;
