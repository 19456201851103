import React from "react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

const ButtonsComp = ({ goToPrev, goToNext }) => {
  const buttonsData = [
    {
      icon: ChevronLeft,
      onClick: goToPrev,
    },
    {
      icon: ChevronRight,
      onClick: goToNext,
    },
  ];

  return (
    <div className="flex space-x-4 justify-center">
      {buttonsData.map((button, index) => (
        <span
          key={index}
          className="cursor-pointer w-[55px] h-[55px] rounded-2xl bg-bg-2 border border-border-1 flex items-center justify-center"
          onClick={button.onClick}
        >
          <button.icon
            sx={{
              fontSize: 37,
              color: "#343434",
            }}
          />
        </span>
      ))}
    </div>
  );
};

export default ButtonsComp;
