import React, { useState } from "react";
import ArrowC from "../../assets/images/ArrowC.png";
import ArrowB from "../../assets/images/ArrowB.png";
import ArrowsB from "../../assets/images/ArrowsB.png";
import ArrowsC from "../../assets/images/ArrowsC.png";
import RocketB from "../../assets/images/RocketB.png";
import RocketC from "../../assets/images/RocketC.png";
import GlobeB from "../../assets/images/GlobeB.png";
import GlobeC from "../../assets/images/GlobeC.png";
import HomeMobile from "../../assets/images/HomeMobile.png";
import flexibility from "../../assets/gifs/mobile.gif";
import Icon from "../../components/Icons";
import { centerClass } from "../../utils";
import { AnimatePresence, motion } from "framer-motion";
import { LazyImage } from "../../components";

const Features = () => {
  const [showCollapse, setShowCollapse] = useState(0);
  const [selectedImage, setSelectedImage] = useState(HomeMobile);
  const [previousIndex, setPreviousIndex] = useState(false);
  const [isExiting, setIsExiting] = useState(false);

  const data = [
    {
      image: ArrowB,
      activeImg: ArrowC,
      preview: HomeMobile,
      title: "Global Connectivity",
      description:
        "Simfree is offering worldwide network access without changing your physical SIM card.",
    },
    {
      image: ArrowsB,
      activeImg: ArrowsC,
      preview: flexibility,
      title: "Flexibility",
      description:
        "Switching between mobile carriers and plans is easy now, allowing you to choose the best network for your needs.",
    },
    {
      image: GlobeB,
      activeImg: GlobeC,
      preview: HomeMobile,
      title: "Security",
      description:
        "Our eSIM technology employs advanced encryption protocols, ensuring the security and privacy of your data wherever you roam.",
    },
    {
      image: RocketB,
      activeImg: RocketC,
      preview: flexibility,
      title: "Remote Provisioning",
      description:
        "Activate, manage, and update your eSIM profiles remotely, eliminating the need for in-store activations and streamlining the user experience.",
    },
  ];

  const handleTitleClick = (index, image) => {
    if (index === showCollapse) return;
    setShowCollapse(index);
    setSelectedImage(image);
    setPreviousIndex(index > showCollapse);
    setIsExiting(true);
  };

  const handleExitComplete = () => {
    setIsExiting(false);
  };

  return (
    <div
      className={`my-[50px] md:my-[100px] text-text-1 md:flex md:justify-between ${centerClass}`}
    >
      <div className="w-full md:w-[48%] flex flex-col">
        <div className="flex items-center space-x-4 mb-6">
          <Icon iconName="logoIcon" className={`h-6 w-6 linearText`} />
          <span className="text-xl linearText font-semibold">
            Features of Simfree
          </span>
        </div>
        <span className="font-bold text-[30px] md:text-[60px] leading-[100%] mb-[60px]">
          How SimFree eSim Works?
        </span>
        {data.map((item, index) => {
          const isOpened = showCollapse === index;

          return (
            <div key={index}>
              <div className="flex space-x-8">
                <span className="!min-w-[53px]">
                  <LazyImage
                    src={isOpened ? item.activeImg : item.image}
                    alt="feature"
                  />
                </span>
                <div className="flex flex-col">
                  <span
                    onClick={() => handleTitleClick(index, item.preview)}
                    className={`font-bold cursor-pointer text-[32px] ${
                      index !== showCollapse && "text-text-6"
                    }`}
                  >
                    {item.title}
                  </span>
                  <AnimatePresence initial={false}>
                    {isOpened && (
                      <motion.div
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={{
                          open: { opacity: 1, height: "auto" },
                          collapsed: { opacity: 0, height: 0 },
                        }}
                        transition={{ duration: 0.3 }}
                      >
                        <span className="text-[20px] font-medium">
                          {item.description}
                        </span>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
              <div className="w-full h-[1px] bg-border-2 my-6" />
            </div>
          );
        })}
      </div>

      <div className="w-full md:w-[48%] bg-bg-2 h-[690px] rounded-[32px] border border-border-2 flex justify-center items-center">
        <AnimatePresence onExitComplete={handleExitComplete}>
          {!isExiting && selectedImage && (
            <motion.img
              key={selectedImage}
              src={selectedImage}
              initial={{
                opacity: 0,
                y: previousIndex ? 100 : -100,
              }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: previousIndex ? 100 : -100 }}
              transition={{ duration: 0.2 }}
              className="h-[80%] w-auto object-contain"
            />
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Features;
