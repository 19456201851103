import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Layout, ProtectedRoute, ScrollToTop } from "./components";
import {
  Home,
  Destinations,
  DestinationDetails,
  About,
  SignUp,
  SignIn,
  Review,
  Career,
  ForgotPswrd,
  NotFound,
  TermsCondition,
  PrivacyPolicy,
  MyEsim,
} from "./pages";
import { auth, db } from "./firebase/FirebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { useAuthStore } from "./store";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "./utils";

function App() {
  const [isReady, setIsReady] = useState(false);
  const { updateUser } = useAuthStore();

  const userFetch = async () => {
    try {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          const docRef = doc(db, "users", user.uid);
          const userDocument = await getDoc(docRef);

          if (userDocument.exists()) {
            updateUser(userDocument.data());
          } else updateUser(null);
        } else {
          updateUser(null);
        }
        setIsReady(true);
      });
    } catch (e) {
      console.warn(e);
    }
  };
  useEffect(() => {
    userFetch();
  }, []);

  if (!isReady) return null;

  return (
    <Router>
      <Layout>
        <Elements stripe={stripePromise}>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/destinations" element={<Destinations />} />
            <Route path="/checkout" element={<DestinationDetails />} />
            <Route path="/about" element={<About />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/resetPass" element={<ForgotPswrd />} />
            <Route path="/review" element={<Review />} />
            <Route path="/career" element={<Career />} />
            <Route path="/terms&conditions" element={<TermsCondition />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route
              path="/myesim"
              element={<ProtectedRoute component={MyEsim} />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Elements>
      </Layout>
    </Router>
  );
}

export default App;
