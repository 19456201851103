import React from "react";

function LogoIcon({ className = "w-6 h-6" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      className={className}
    >
      <g clipPath="url(#clip0_130_3328)">
        <path
          fill="currentColor"
          d="M19.916 16.084a1.68 1.68 0 01-2.376 0l-2.897-2.896a1.68 1.68 0 010-2.376l2.897-2.896a1.68 1.68 0 012.376 0l2.896 2.896a1.68 1.68 0 010 2.376l-2.896 2.896zm-13.456 0a1.68 1.68 0 01-2.376 0l-2.896-2.896a1.68 1.68 0 010-2.376l2.896-2.896a1.68 1.68 0 012.376 0l2.897 2.896a1.68 1.68 0 010 2.376L6.46 16.084zm6.728 6.728a1.68 1.68 0 01-2.376 0l-2.897-2.896a1.68 1.68 0 010-2.376l2.897-2.897a1.68 1.68 0 012.376 0l2.896 2.897a1.68 1.68 0 010 2.376l-2.896 2.896zm0-13.455a1.68 1.68 0 01-2.376 0L7.915 6.46a1.68 1.68 0 010-2.376l2.897-2.896a1.68 1.68 0 012.376 0l2.896 2.896a1.68 1.68 0 010 2.376l-2.896 2.897z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_130_3328">
          <path fill="currentColor" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default LogoIcon;
