import React, { useEffect } from "react";
import Brands from "../content/Career/Brands";
import Banner from "../content/Career/Banner";
import { useDataStore } from "../store";

export default function Career() {
  const { setFooter } = useDataStore();

  useEffect(() => {
    setFooter({
      isFooter: true,
      isFooterImage: true,
    });
  }, []);

  return (
    <div>
      <Banner />
      <Brands />
    </div>
  );
}
