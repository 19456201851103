import React from "react";
import { Icon } from "../../components";
import { centerClass } from "../../utils";

export default function Comission() {
  let data = [
    {
      step: "Step 1",
      desc: "Share Your Promo Code: Generate a code and give it to your friends.",
    },
    {
      step: "Step 2",
      desc: "Friends save 3$: When they use your code to purchase an eSim, they get 3$ off.",
    },
    {
      step: "Step 3",
      desc: "You Earn 3$ Credit: You get 3$ to use on your next eSim purchase.",
    },
  ];

  return (
    <div className="w-full flex bg-bg-2 justify-between">
      <div
        className={`!py-[50px] md:py-[100px] ${centerClass} text-left md:text-center`}
      >
        <div className="w-full gap-3 flex items-center md:justify-center">
          <Icon iconName="logoIcon" className={`h-6 w-6 linearText`} />
          <p className="text-base md:text-xl font-medium linearText">
            Commission-Based/ Referral
          </p>
        </div>
        <div className="w-[full] flex md:justify-center">
          <p className="text-[40px] md:text-[60px] leading-[100%] w-[75%] font-bold text-black mt-4">
            With SimFree, you can refer friends and earn credit. Here’s how it
            works:
          </p>
        </div>

        <div className="w-full flex flex-wrap justify-center gap-[32px] mt-10">
          {data?.map((e, i) => {
            return (
              <div
                key={i}
                className="w-full md:w-[392px] flex flex-col bg-white p-[24px] space-y-3 rounded-[32px]"
              >
                <span className="text-4xl text-text-1 font-bold">
                  {e?.step}{" "}
                </span>

                <span className="text-xl text-text-1 font-medium">
                  {e?.desc}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
