import React from "react";

function Cart({ className = "w-6 h-6" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
      className={className}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8.667 22.667h14.439c1.266 0 1.9 0 2.416-.23a2.667 2.667 0 001.124-.938c.318-.466.431-1.09.658-2.336l1.8-9.906c.08-.431.119-.647.058-.816a.667.667 0 00-.293-.351C28.714 8 28.494 8 28.055 8H6.667m-4-5.333h1.755c.324 0 .485 0 .616.059a.667.667 0 01.283.241c.079.12.104.28.155.6L9.19 27.1c.05.32.076.48.155.6.07.105.167.189.283.24.13.06.292.06.616.06h15.088"
      ></path>
    </svg>
  );
}

export default Cart;
