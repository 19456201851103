import React, { useState } from "react";
import { Modal } from "@mui/material";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { Close } from "@mui/icons-material";
import ButtonComp from "./ButtonComp";
import { usePersistData } from "../store";
import { useNavigate } from "react-router-dom";

const StripeCardModal = ({
  open,
  handleClose,
  stripeData,
  setSuccess,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { setCart } = usePersistData();

  const navigate = useNavigate()

  const closeModal = () => {
    setError(null);
    setLoading(false);
    handleClose();
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);

    try {
      if (!stripe || !elements) {
        throw new Error("Stripe.js has not loaded yet.");
      }

      const { error, paymentIntent } = await stripe.confirmCardPayment(
        stripeData.paymentIntent,
        {
          payment_method: {
            card: elements.getElement(CardNumberElement),
          },
        }
      );

      if (error) {
        setError(error.message);
        throw new Error(error.message);
      }

      // Additional logic after successful payment
      closeModal(true);
      setCart(null);
      setSuccess(true);
      setTimeout(() => {
      navigate("/myesim");
      }, 3000);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const inputClass = "w-full px-4 py-2 border border-border-1 rounded-xl";

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="w-full h-full flex justify-center items-center">
          <div className="w-[90%] md:w-[500px] bg-white p-6 rounded-2xl space-y-6">
            <div className="flex items-center justify-between my-4">
              <span className="font-semibold text-2xl leading-[100%]">
                Card Information
              </span>
              <span onClick={closeModal} className="cursor-pointer">
                <Close />
              </span>
            </div>

            <div className={inputClass}>
              <label>Card Number</label>
              <CardNumberElement />
            </div>
            <div className={inputClass}>
              <label>Expiry Date</label>
              <CardExpiryElement />
            </div>
            <div className={inputClass}>
              <label>CVC</label>
              <CardCvcElement />
            </div>

            {error && (
              <span className="text-text-11 text-base font-medium text-center">
                {error}
              </span>
            )}

            <ButtonComp
              text={`Pay - $${stripeData?.amount || 0}`}
              handleClick={handleSubmit}
              disabled={isLoading}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default StripeCardModal;
