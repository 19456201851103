import React, { useRef, useState } from "react";
import { ButtonsComp, Icon, LazyImage, SliderComp } from "../../components";
import { centerClass } from "../../utils";
import { useNavigate } from "react-router-dom";
import { usePersistData } from "../../store";
import { Tooltip } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";

const PopularCountries = ({ topCountries }) => {
  const { setCountry, setCart } = usePersistData();

  const [slidera, setSliderA] = useState(null);

  const containerRef = useRef();
  const navigate = useNavigate();

  const nextSlide = () => {
    slidera && slidera.slickNext();
  };

  const prevSlide = () => {
    slidera && slidera.slickPrev();
  };

  return (
    <div className="my-[50px] md:my-[100px] ">
      <div className={`flex flex-col ${centerClass}`}>
        <div className="flex items-center space-x-4 mb-6">
          <Icon iconName="logoIcon" className={`h-6 w-6 linearText`} />
          <span className="text-xl linearText font-semibold">
            Top Countries
          </span>
        </div>{" "}
        <div className="flex justify-between">
          <span className="font-bold text-[30px] md:text-[60px] leading-[100%]">
            Popular Destination
          </span>
          <span className="md:block hidden">
            <ButtonsComp goToPrev={prevSlide} goToNext={nextSlide} />
          </span>
        </div>
      </div>
      <div
        className={`flex ${centerClass} overflow-hidden w-full`}
        ref={containerRef}
      >
        <SliderComp setSlider={setSliderA}>
          {topCountries.map((item, index) => (
            <div
              key={index}
              className="px-4 cursor-pointer !w-[95%] text-text-1 pt-4 pb-5 rounded-[32px] relative md:h-[315px] bg-bg-2 border border-border-1 mt-4"
              onClick={() => {
                setCountry(item);
                setCart(null);
                navigate(`/checkout`);
              }}
            >
              <LazyImage
                src={item.thumbnail}
                alt={item.name}
                className="w-full !h-[238px] rounded-3xl object-cover"
              />
              <div className="flex text-text-1 flex-row items-center justify-between p-2">
                <Tooltip title={item.name} placement={"bottom"} arrow>
                  <span className="font-bold text-[32px] truncate">
                    {item.name}
                  </span>
                </Tooltip>
                <div className="items-center">
                  <span className="text-xs font-medium">
                    <ArrowForward
                      className="cursor-pointer"
                      sx={{
                        fontSize: 32,
                        color: "#343434",
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className="absolute top-8 left-8">
                <LazyImage
                  src={item.flagUrl}
                  alt={item.name}
                  className="w-[54px] h-[54px] object-cover rounded-full"
                />
              </div>
            </div>
          ))}
        </SliderComp>
      </div>
      <span className={`my-4 block md:hidden ${centerClass}`}>
        <ButtonsComp goToPrev={prevSlide} goToNext={nextSlide} />
      </span>
    </div>
  );
};

export default PopularCountries;
