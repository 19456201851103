import React from "react";
import { motion } from "framer-motion";
import { CircularProgress } from "@mui/material";

const ButtonComp = ({
  text,
  className = "",
  bgColor = "",
  animate = {},
  handleClick = () => {},
  disabled = false,
  loader = false,
}) => {
  return (
    <motion.div
      className={`px-5 py-4 cursor-pointer text-center rounded-2xl text-bg-4 text-base font-semibold ${
        bgColor ? bgColor : "linearBg"
      } ${className} ${disabled && "opacity-50 cursor-not-allowed"}`}
      onClick={() => !disabled && handleClick()}
      {...animate}
    >
      {loader ? (
        <CircularProgress size={16} color="inherit" />
      ) : (
        text || "Get your eSIM"
      )}
    </motion.div>
  );
};

export default ButtonComp;
