import React, { useEffect } from "react";
import { Banner, Brands, Comission } from "../content/Review";
import { Testimonials } from "../content/HomePage";
import { useDataStore } from "../store";

export default function Review() {
  const { setFooter } = useDataStore();

  useEffect(() => {
    setFooter({
      isFooter: true,
      isFooterImage: true,
    });
  }, []);

  return (
    <div>
      <Banner />

      <Brands />
      <Comission />

      <div id="reviewTestimonial">
        <Testimonials />
      </div>
    </div>
  );
}
