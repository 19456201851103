import React from "react";
import homePoster from "../../assets/images/homePoster.png";
import flags from "../../assets/gifs/flags.gif";
import regist from "../../assets/gifs/regist.gif";
import { Icon, LazyImage } from "../../components";
import { centerClass } from "../../utils";

const data = [
  {
    icon: "logoIcon",
    img: regist,
    label: "Simple",
    title: "Customisable business solutions",
    desc: "Perfect for companies of all sizes, offering streamlined connectivity for employees worldwide.",
  },
  {
    icon: "logoIcon",
    img: flags,
    label: "Powerfull",
    title: "Powerful Connectivity",
    desc: "Count on Us for Uninterrupted Connectivity",
  },
];

export default function AdsPoster() {
  return (
    <div
      className={`${centerClass} my-[50px] md:my-[100px] md:flex justify-between overflow-hidden`}
    >
      <div className="w-full md:w-[48%] bg-bg-1 p-4 md:p-8 rounded-3xl flex flex-col space-y-2">
        <div className="gap-3 flex items-center">
          <Icon iconName="logoIcon" className={`h-6 w-6 text-bg-2`} />
          <span className="text-xl font-medium text-bg-2">Anywhere</span>
        </div>

        <div className="w-full flex justify-center items-center ">
          <LazyImage className="w-[300px] md:w-[400px]" src={homePoster} />
        </div>

        <span className="text-3xl md:text-6xl font-bold text-bg-2">
          Stay Connected With The Best
        </span>

        <span className="text-base md:text-xl font-medium text-bg-2">
          Choose from various individual plans tailored to suit your usage
          patterns and budget.
        </span>
      </div>

      <div className="w-full md:w-[48%] flex flex-col justify-between">
        {data.map((v, i) => {
          const classes =
            i === 0
              ? "bg-border-1 text-black mt-4 md:mt-0"
              : "bg-bg-4 text-white max-h-[300px] mt-4";
          return (
            <div
              key={i}
              className={`w-full flex justify-between overflow-hidden rounded-3xl ${classes}`}
            >
              <div className="w-full md:w-max p-4 md:p-8 md:pr-0">
                <div className="gap-3 flex items-center">
                  <Icon
                    iconName="logoIcon"
                    className={`h-6 w-6 ${
                      i === 0 ? "text-black" : "text-white"
                    }`}
                  />
                  <span className="text-base md:text-xl font-medium">
                    {v.label}
                  </span>
                </div>
                <p className="text-3xl font-bold my-4">{v.title}</p>
                <p className="text-base md:text-xl font-medium">{v.desc}</p>
              </div>
              <div className="flex-col items-center justify-center hidden md:flex">
                <LazyImage
                  src={v.img}
                  className="object-cover min-w-[250px] rounded-tl-[24px] rounded-bl-[24px]"
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
