import { Close } from "@mui/icons-material";
import { Modal } from "@mui/material";
import React, { useState } from "react";
import Icon from "../../components/Icons";
import { LazyImage } from "../../components";

const QrModal = ({ open, handleClose, Data }) => {
  const [isCopied, setisCopied] = useState(false);

  const copyTheCode = () => {
    setisCopied(true);
    navigator.clipboard.writeText(Data?.matchingID);
  };

  setTimeout(() => {
    if (isCopied) {
      setisCopied(false);
    }
  }, 900);
  return (
    <Modal open={open} onClose={handleClose}>
      <div className="w-full h-full flex justify-center items-center text-text-1">
        <div className="w-[90%] md:w-[500px] bg-white py-8 justify-center flex flex-col items-center space-y-4 rounded-[32px]">
          <span
            onClick={handleClose}
            className="cursor-pointer justify-end px-8 w-full flex"
          >
            <Close />
          </span>
          <p className="font-bold text-base">
            Scan below QR Code to Install e-sim.
          </p>
          <LazyImage
            src={Data?.qrCodeImageUrl}
            alt={"Activate your sim"}
            className="w-60 h-60"
          />
          <p className="w-full text-[20px] font-bold px-8">Activation code:</p>
          <span
            onClick={() => {
              copyTheCode();
            }}
            className="w-[90%] text-base border justify-between border-text-6 py-2 px-4 cursor-pointer items-center flex rounded-full"
          >
            {Data?.matchingID}
            <Icon
              iconName="copyIcon"
              className={`h-2 w-2 linearText cursor-pointer`}
            />
          </span>

          {isCopied ? (
            <>
              <p className="text-base text-green-600">copied to clipboard!</p>
            </>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

export default QrModal;
