import React from "react";
import NSBC from "../../assets/images/NCSBN.png";
import JohnBrand from "../../assets/images/Frame 3153.png";
import AccentureBrand from "../../assets/images/Accenture.png";
import pdmaBrand from "../../assets/images/PDMA.png";
import thermoBrand from "../../assets/images/ThermoFisher.png";
import { centerClass } from "../../utils";
import { LazyImage, SliderComp } from "../../components";

export default function Brands() {
  const images = [
    {
      image: JohnBrand,
    },
    {
      image: pdmaBrand,
    },
    {
      image: AccentureBrand,
    },
    {
      image: thermoBrand,
    },
    {
      image: NSBC,
    },
  ];
  return (
    <div
      className={`flex-col py-[50px] md:py-[100px] items-center flex justify-center ${centerClass}`}
    >
      <p className="text-xl text-center font-semibold mb-4">
        Trusted by Thousands
      </p>

      <SliderComp
        setSlider={() => {}}
        extraSettings={{
          speed: 5000,
          autoplaySpeed: 0,
          cssEase: "linear",
          arrows: false,
          pauseOnHover: false,
        }}
        settings={{
          show: 5,
          768: 2,
          1024: 4,
          1440: 5,
        }}
      >
        {images.map((v, i) => (
          <span
            key={i}
            className="!flex !items-center !justify-center !h-[60px] md:!h-[105px]"
          >
            <LazyImage src={v.image} key={i} className="" />
          </span>
        ))}
      </SliderComp>
    </div>
  );
}
