import React from "react";
import { Icon, LazyImage, SliderComp } from "../../components";
import infogicsBrand from "../../assets/images/Infogix.png";
import noryonBrand from "../../assets/images/nouryon-vector-logo.png";
import nrfBrand from "../../assets/images/NRF.png";
import questBrand from "../../assets/images/Quest Diagnostics.png";
import { centerClass } from "../../utils";

export default function Brands() {
  return (
    <div
      className={`${centerClass} flex-col !space-y-8 py-[50px] md:py-[100px] items-center flex justify-center`}
    >
      <div className="w-full gap-3 flex items-center justify-center">
        <Icon iconName="logoIcon" className={`h-6 w-6 linearText`} />
        <p className="text-xl font-medium linearText text-center">Investors</p>
      </div>
      <p className="text-[48px] md:text-6xl font-bold text-text-1 text-center mb-10">
        See Who Are Investing in Simfree!
      </p>

      <div className={`flex space-x-8 w-[100%] overflow-hidden`}>
        <SliderComp
          setSlider={() => {}}
          extraSettings={{
            speed: 5000,
            autoplaySpeed: 0,
            cssEase: "linear",
            arrows: false,
            pauseOnHover: false,
          }}
          settings={{
            show: 4,
            768: 2,
            1024: 4,
            1440: 5,
          }}
        >
          <span className="w-[264px] h-[105px] flex items-center">
            <LazyImage className="" src={infogicsBrand} />
          </span>
          <span className="w-[159px] h-[105px] flex items-center">
            <LazyImage className="" src={noryonBrand} />
          </span>

          <span className="w-[194px] h-[105px] flex items-center">
            <LazyImage className="" src={nrfBrand} />
          </span>
          <span className="w-[197px] h-[105px] flex items-center">
            <LazyImage className="" src={questBrand} />
          </span>
        </SliderComp>
      </div>
    </div>
  );
}
