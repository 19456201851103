import React from "react";
import RocketColor from "../../assets/images/RocketColor.png";
import { Modal } from "@mui/material";
import { Close } from "@mui/icons-material";
import { LazyImage } from "../../components";

const SuccessModal = ({ open, handleClose }) => {

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="w-full h-full flex justify-center items-center text-text-1">
        <div className="w-[90%] md:w-[500px] bg-white p-6 rounded-[32px] text-center flex flex-col">
          <span
            onClick={handleClose}
            className="cursor-pointer flex justify-end"
          >
            <Close />
          </span>
          <LazyImage
            src={RocketColor}
            alt="Rocket"
            className="w-[172px] h-[239px] mx-auto my-[50px]"
          />

          <span className="text-2xl md:text-3xl font-bold">
            Your eSIM ready to Activate!
          </span>

          <span className="text-base md:text-xl font-medium text-text-9">
            Download app to see eSIM instruction and get more offers to your
            travelling journey.{" "}
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default SuccessModal;
