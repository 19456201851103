import React, { useEffect } from "react";
import { centerClass } from "../utils";
import { useDataStore } from "../store";

export default function TermsCondition() {

  const { setFooter } = useDataStore();

  useEffect(() => {
    setFooter({
      isFooter: true,
      isFooterImage: true,
    });
  }, []);
  return (
    <div className={`${centerClass} md:w-[90%] w-full`}>
      <main className="container mx-auto px-4 py-8">
        <span className="flex w-full justify-center items-center">
          <h1 className="text-4xl text-text-2 font-bold mb-6">
            Terms and Conditions
          </h1>
        </span>

        <section className="mb-8">
          <h3 className="text-2xl font-semibold mb-4">1. Introduction</h3>
          <p className="mb-4">
            Welcome to SimFree. These Terms and Conditions outline the rules and
            regulations for the use of our website and services. By accessing or
            using our website, you agree to comply with these terms. If you do
            not agree with any part of these terms, you must not use our
            website.
          </p>
          <p className="mb-4">
            We reserve the right to modify these terms at any time. Changes will
            be effective immediately upon posting on this page. It is your
            responsibility to review these terms periodically for any changes.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-2xl font-semibold mb-4">2. Use of the Site</h3>
          <p className="mb-4">
            You agree to use the site in accordance with all applicable laws and
            regulations. You are responsible for ensuring that your use of the
            site does not violate any laws or regulations in your jurisdiction.
          </p>
          <p className="mb-4">
            You agree not to engage in any activity that disrupts or interferes
            with the functioning of the site or its servers. This includes, but
            is not limited to, transmitting any harmful or disruptive code or
            data, or engaging in activities that degrade the performance or
            availability of the site.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-2xl font-semibold mb-4">
            3. Intellectual Property
          </h3>
          <p className="mb-4">
            All content on this website, including but not limited to text,
            graphics, logos, images, and software, is the property of SimFree or
            its licensors and is protected by copyright, trademark, and other
            intellectual property laws.
          </p>
          <p className="mb-4">
            You may not reproduce, distribute, modify, or otherwise use any
            content from the site without our express written permission.
            Unauthorized use of our intellectual property may result in legal
            action.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-2xl font-semibold mb-4">4. User Accounts</h3>
          <p className="mb-4">
            To access certain features of the site, you may be required to
            create an account. You agree to provide accurate and complete
            information when creating an account and to update your information
            as necessary to keep it accurate and complete.
          </p>
          <p className="mb-4">
            You are responsible for maintaining the confidentiality of your
            account credentials and for all activities that occur under your
            account. You must notify us immediately of any unauthorized use of
            your account or any other breach of security.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-2xl font-semibold mb-4">
            5. Limitation of Liability
          </h3>
          <p className="mb-4">
            SimFree shall not be liable for any indirect, incidental, special,
            consequential, or punitive damages arising out of or related to your
            use of the site or services. This includes, but is not limited to,
            damages for loss of profits, data, or other intangible losses.
          </p>
          <p className="mb-4">
            Our total liability to you for any claim arising out of your use of
            the site or services shall be limited to the amount you paid to us
            for the use of the site or services in the six months preceding the
            claim.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-2xl font-semibold mb-4">6. Privacy Policy</h3>
          <p className="mb-4">
            Your use of the site is also governed by our Privacy Policy, which
            outlines how we collect, use, and protect your personal information.
            Please review our Privacy Policy to understand our practices and
            your rights concerning your data.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-2xl font-semibold mb-4">7. Governing Law</h3>
          <p className="mb-4">
            These Terms and Conditions shall be governed by and construed in
            accordance with the laws of the jurisdiction in which SimFree
            operates, without regard to its conflict of law principles. Any
            disputes arising from these terms shall be resolved in the courts
            located within that jurisdiction.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-2xl font-semibold mb-4">8. Changes to Terms</h3>
          <p className="mb-4">
            We reserve the right to update or modify these Terms and Conditions
            at any time. Any changes will be posted on this page with an updated
            effective date. Your continued use of the site after any changes
            constitutes your acceptance of the new terms.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-2xl font-semibold mb-4">9. Contact Us</h3>
          <p className="mb-4">
            If you have any questions or concerns about these Terms and
            Conditions, please contact us at support@simfree.com. We are here to
            help and will respond to your inquiries in a timely manner.
          </p>
        </section>
      </main>
    </div>
  );
}
