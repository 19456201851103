import React, { useState, useEffect } from "react";
import { functions, db } from "../firebase/FirebaseConfig";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useAuthStore, useDataStore } from "../store";
import { centerClass } from "../utils";
import { SimCardComp } from "../content/ESIMS";
import { CircularProgress } from "@mui/material";

export default function MyeSim() {
  const { user } = useAuthStore();

  const [sims, setSims] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setFooter } = useDataStore();

  useEffect(() => {
    setFooter({
      isFooter: true,
      isFooterImage: true,
    });
  }, []);

  useEffect(() => {
    const fetchSims = async () => {
      setSims([]);
      setLoading(true);

      if (user) {
        const userId = user.id;
        try {
          const res = await getDocs(collection(db, `users/${userId}/esims`));
          const arr = res.docs.map((doc) => doc.data());

          const sortedSims = await Promise.all(
            arr.map(async (v) => {
              const simDetails = await loadSim(v);
              return { data: v, ...simDetails };
            })
          ).then((simData) =>
            simData.sort((a, b) => {
              const aPlanActive = a.usage?.sim?.status === "ACTIVE";
              const bPlanActive = b.usage?.sim?.status === "ACTIVE";

              if (aPlanActive !== bPlanActive) {
                return aPlanActive ? -1 : 1;
              }

              const dateA = a.data?.dateCreated
                ? new Date(a.data.dateCreated).getTime()
                : -1;
              const dateB = b.data?.dateCreated
                ? new Date(b.data.dateCreated).getTime()
                : -1;

              return dateB - dateA;
            })
          );

          setSims(sortedSims);
        } catch (err) {
          console.error("Error fetching SIMs:", err);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchSims();
  }, [user]);

  const loadSim = async (esim) => {
    try {
      const countryDoc = await getDoc(
        doc(db, `countries-meta/${esim.isoCode}`)
      );
      const countryData = countryDoc.exists() ? countryDoc.data() : undefined;

      let planData;
      try {
        const planDoc = await getDoc(
          doc(db, `plans/${esim.region}/plans/${esim.planID}`)
        );
        planData = planDoc.exists() ? planDoc.data() : undefined;
      } catch (err) {
        console.warn("Error fetching plan:", err);
        planData = undefined;
      }

      let esimUsage;
      try {
        const checkUsage = httpsCallable(functions, "checkUsage");
        const response = await checkUsage({ esimID: esim.iccid });
        esimUsage = response.data;
      } catch (err) {
        console.warn("Error checking usage:", err);
        esimUsage = undefined;
      }

      return {
        country: countryData,
        plan: planData,
        usage: esimUsage,
      };
    } catch (err) {
      console.error("Unexpected error:", err);
    }
  };

  return (
    <div
      className={`flex flex-wrap gap-4 my-[50px] justify-between ${centerClass}`}
    >
      <span className="font-bold text-[30px] md:text-[60px] leading-[100%] mb-10">
        My eSIM's
      </span>
      {loading ? (
        <div className="flex w-full justify-center items-center h-screen">
          <CircularProgress />
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 w-full">
          {sims?.map((e, i) => {
            return <SimCardComp simData={e} key={Math.random() * 1000} />;
          })}
        </div>
      )}
    </div>
  );
}
