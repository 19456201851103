import Network from "./Network";
import Logo from "./logo";
import Cart from "./Cart";
import HelpIcon from "./HelpIcon";
import HomeIcon from "./HomeIcon";
import PackageIcon from "./PackageIcon";
import OrgIcon from "./OrgIcon";
import LogoIcon from "./Icon";
import AppStore from "./AppStore";
import PlayStore from "./PlayStore";
import SummaryBasket from "./SummaryBasket";
import QRCode from "./QRCode";
import SimpleLogo from "./SimpleLogo";
import MenuIcon from "./MenuIcon";
import LogoutIcon from "./Logout";
import Profile from "./Profile";
import ESimIcon from "./EsimIcon";
import CopyIcon from "./CopyIcon";

const Icon = ({ iconName, ...rest }) => {
  switch (iconName) {
    case "logo":
      return <Logo {...rest} />;
    case "network":
      return <Network {...rest} />;
    case "Cart":
      return <Cart {...rest} />;
    case "HelpIcon":
      return <HelpIcon {...rest} />;
    case "HomeIcon":
      return <HomeIcon {...rest} />;
    case "PackageIcon":
      return <PackageIcon {...rest} />;
    case "OrgIcon":
      return <OrgIcon {...rest} />;
    case "logoIcon":
      return <LogoIcon {...rest} />;
    case "appStore":
      return <AppStore {...rest} />;
    case "playStore":
      return <PlayStore {...rest} />;
    case "summaryBasket":
      return <SummaryBasket {...rest} />;
    case "QRCode":
      return <QRCode {...rest} />;
    case "simpleLogo":
      return <SimpleLogo {...rest} />;
    case "menuIcon":
      return <MenuIcon {...rest} />;
    case "logoutIcon":
      return <LogoutIcon {...rest} />;
    case "profileIcon":
      return <Profile {...rest} />;
    case "esimIcon":
      return <ESimIcon {...rest} />;
    case "copyIcon":
      return <CopyIcon {...rest} />;
    default:
      return <Logo {...rest} />;
  }
};

export default Icon;
