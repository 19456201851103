import React, { useState } from "react";
import { Icon, LazyImage, SearchInput } from "../../components";
import { ArrowForward } from "@mui/icons-material";
import { centerClass } from "../../utils";
import { useNavigate } from "react-router-dom";
import { usePersistData } from "../../store";
import { Tooltip } from "@mui/material";

const ShowAllCountries = ({ allCountries }) => {
  const navigate = useNavigate();
  const [dataArr, setDataArr] = useState(allCountries);

  const { setCountry, setCart } = usePersistData();

  const handleSearch = (value) => {
    const arr = value?.length
      ? allCountries.filter((item) => {
          return item.name.toLowerCase().includes(value.toLowerCase());
        })
      : allCountries;
    setDataArr(arr);
  };

  return (
    <div className={`my-[50px] md:my-[100px] ${centerClass}`}>
      <div className="flex flex-col">
        <div className="flex items-center space-x-4 mb-6">
          <Icon iconName="logoIcon" className={`h-6 w-6 linearText`} />
          <span className="text-xl linearText font-semibold">
            All Countries
          </span>
        </div>
        <span className="font-bold text-[30px] md:text-[60px]leading-[100%]">
          Explore Countries
        </span>
      </div>

      <SearchInput
        placeholder="Search"
        handleChange={handleSearch}
        className="mt-10 mb-[60px] w-full md:w-[40%]"
      />

      <div className="my-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {dataArr.length ? (
          dataArr.map((item, index) => (
            <div
              key={index}
              className="px-4 text-text-1 pt-4 pb-5 bg-white rounded-[32px] shadow-lg relative !w-full !h-[326px] cursor-pointer"
              onClick={() => {
                setCountry(item);
                setCart(null);
                navigate(`/checkout`);
              }}
            >
              <LazyImage
                src={item.thumbnail}
                alt={item.name}
                className="w-full h-[238px] rounded-3xl object-cover"
              />
              <div className="flex flex-row items-center justify-between p-2">
                <Tooltip title={item.name} placement={"bottom"} arrow>
                  <span className="font-semibold text-[32px] truncate text-ellipsis max-w-[200px]">
                    {item.name}
                  </span>
                </Tooltip>
                <div className="items-center">
                  <span className="text-xs font-medium">
                    <ArrowForward
                      className="cursor-pointer"
                      sx={{
                        fontSize: 32,
                        color: "#343434",
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className="absolute top-8 left-8">
                <LazyImage
                  src={item.flagUrl}
                  alt={item.name}
                  className="w-[54px] h-[54px] object-cover rounded-full"
                />
              </div>
            </div>
          ))
        ) : (
          <div className="text-text-10 flex justify-center font-bold text-3xl">
            No data found
          </div>
        )}
      </div>
    </div>
  );
};

export default ShowAllCountries;
