import React, { useState } from "react";
import { centerClass } from "../../utils";
import { ButtonComp, Icon, LazyImage } from "../../components";
import { Close } from "@mui/icons-material";
import { usePersistData } from "../../store";
import ShowPlans from "./ShowPlans";
import ShowDescription from "./ShowDescription";
import ShowFeatures from "./ShowFeatures";
import ShowConditions from "./ShowConditions";

const Details = ({ plans, selectedComp, setSelectedComp, handleGetESim }) => {
  const { cart, setCart, country } = usePersistData();

  const [selectedOption, setSelectedOption] = useState("Plan");

  const plansArr = plans.sort((a, b) => a.price - b.price);

  const options = [
    { name: "Plan", comp: <ShowPlans plans={plansArr} /> },
    {
      name: "Description",
      comp: (
        <ShowDescription
          text={`Stay connected for business or leisure in ${country?.name} with our tailored Business Package.`}
        />
      ),
    },
    {
      name: "Features",
      comp: (
        <ShowFeatures text="Roaming capabilities, premium data allowance, and special rates for business calls." />
      ),
    },
    {
      name: "Terms & Conditions",
      comp: (
        <ShowConditions text="Roaming charges may apply. Rates for business calls may vary." />
      ),
    },
  ];

  return (
    <div className={`lg:flex text-text-1 justify-between mt-10 ${centerClass}`}>
      <div className="w-full lg:w-[50%]">
        <div className="flex justify-between">
          <div className="flex flex-col space-y-5">
            <span className="text-base md:text-xl">Data Only</span>
            <span className="text-[36px] md:text-[48px] leading-[100%] font-bold">
              {country?.name}
            </span>
          </div>

          <div className="flex flex-col space-y-5 text-right">
            <span className="font-medium text-base md:text-xl">Start from</span>
            <span className="text-[36px] md:text-[48px] leading-[100%] font-bold">
              ${country?.startingFrom || 0}
            </span>
          </div>
        </div>

        <div className="w-full h-[1px] bg-border-1 my-6" />

        <div className="flex space-x-8 overflow-scroll">
          {options.map((item, index) => (
            <span
              key={index}
              className={`cursor-pointer font-semibold text-base md:text-2xl hover:text-bg-4 !min-w-max ${
                item.name == selectedOption ? "text-bg-4" : "text-text-7"
              }`}
              onClick={() => {
                setSelectedComp(item.comp);
                setSelectedOption(item.name);
              }}
            >
              {item.name}
            </span>
          ))}
        </div>

        {selectedComp}

        <div className="w-full h-[1px] bg-border-1 my-6" />

        <span className="text-base md:text-xl font-medium">
          With Simfree eSIM, the world is at your fingertips. Stay connected,
          share your experiences, and make memories that last a lifetime, all
          without missing a beat. Start your journey with Simfree.io today and
          unlock endless possibilities.
        </span>
      </div>

      <div className="space-y-8 w-full lg:w-[40%] mt-4 lg:mt-0">
        <div className="rounded-3xl w-full border border-border-2 p-6 text-text-1 flex flex-col space-y-8">
          <span className="font-bold text-2xl">Order Summary</span>
          {cart ? (
            <div className="space-y-6 flex flex-col">
              <div className="w-full border border-border-2 rounded-3xl p-6 bg-bg-2 space-y-4">
                <div className="flex justify-between items-center">
                  <span className="flex items-center text-text-1 text-base font-semibold">
                    <LazyImage
                      src={country.flagUrl}
                      alt={country.name}
                      className="w-6 h-6 mr-2 object-cover rounded-full"
                    />
                    {country.name}
                  </span>
                  <span
                    className="cursor-pointer"
                    onClick={() => setCart(null)}
                  >
                    <Close size={20} />
                  </span>
                </div>

                <div className="flex justify-between items-center">
                  <span className="flex items-center text-text-1 text-base font-semibold space-x-2">
                    <Icon iconName="network" className={`h-6 w-6 text-black`} />
                    <span className="font-semibold text-2xl">
                      {cart.size} GB
                    </span>
                    <span className="text-sm font-medium">
                      | {cart.days} days
                    </span>
                  </span>
                  <span className="font-semibold text-2xl">${cart.price}</span>
                </div>
              </div>
              <div className="w-full h-[1px] bg-border-1" />
              <ButtonComp
                text={"Get your eSIM"}
                className="text-base md:text-xl"
                handleClick={handleGetESim}
              />
            </div>
          ) : (
            <>
              <span className="flex justify-center">
                <Icon iconName="summaryBasket" />
              </span>
              <div className="flex flex-col space-y-2 text-center">
                <span className="text-text-8 text-base md:text-xl font-semibold">
                  Look’s like you don’t have a plan
                </span>
                <span className="text-text-7 font-medium">
                  Out of eSIMs? Like a runway with no travelers. Let's get ready
                  to explore!
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Details;
