import React from "react";
import fullPhone from "../../assets/images/fullPhone.png";
import mailbox from "../../assets/images/mailbox.png";
import puzzle from "../../assets/images/puzzle.png";
import compass from "../../assets/images/compass.png";
import dummyProfiles from "../../assets/images/dummyProfiles.png";
import { motion } from "framer-motion";
import { LazyImage } from "../../components";

const HeroSection = () => {
  return (
    <div className="flex justify-center md:justify-between w-full my-[50px] md:!my-[100px]">
      <div className="hidden md:flex text-base lg:text-[24px] flex-col items-end justify-between max-w-[290px]">
        <span className="flex flex-col items-end p-4 mt-14">
          <motion.img
            src={puzzle}
            className="h-[74px] w-auto object-contain"
            animate={{ y: [-20, 0, -20] }}
            transition={{ duration: 4, repeat: Infinity, ease: "easeInOut" }}
          />
          <span className="text-right">
            Switch between cariers and plans seamlessly.
          </span>
        </span>
        <span className="flex flex-col items-end p-4 mb-32">
          <motion.img
            src={mailbox}
            className="h-[74px] w-auto object-contain"
            animate={{ y: [-20, 0, -20] }}
            transition={{ duration: 4, repeat: Infinity, ease: "easeInOut" }}
          />
          <span className="text-right">
            Break free from the limitations of traditional SIM cards.
          </span>
        </span>
      </div>
      <span>
        <LazyImage src={fullPhone} />
      </span>
      <div className="hidden md:flex text-base lg:text-[24px] flex-col justify-between max-w-[290px]">
        <span className="p-4">
          <motion.img
            src={compass}
            className="h-20 lg:h-[74px] w-auto object-contain"
            animate={{ y: [-20, 0, -20] }}
            transition={{ duration: 4, repeat: Infinity, ease: "easeInOut" }}
          />
          <span className="text-right">
            Enjoy the freedom to get connect, travel and communicate like never
            before{" "}
          </span>
        </span>
        <div className="bg-bg-2 p-6 text-base border border-border-1 rounded-[32px] flex flex-col items-start space-y-4">
          <LazyImage
            src={dummyProfiles}
            className="h-[60px] w-auto object-contain"
          />
          <span className="font-medium">
            “SimFree has made my travel experience so much easier. I never have
            to worry about staying connected abroad.”
          </span>
          <span className="font-bold">– Sarah</span>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
