import React, { useEffect, useState } from "react";
import { ButtonComp, InputComp } from "../components";
import { signInEmailAndPassword } from "../services/FirebaseAuthManager";
import { useNavigate } from "react-router-dom";
import { useAuthStore, useDataStore } from "../store";
import { centerClass } from "../utils";

const formArr = [
  {
    type: "email",
    placeholder: "Email",
  },
  {
    type: "password",
    placeholder: "Password",
  },
];

const SignIn = () => {
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setLoader] = useState(false);

  const { updateUser, user } = useAuthStore();
  const { setFooter } = useDataStore();

  const navigate = useNavigate();

  useEffect(() => {
    setFooter({
      isFooter: true,
      isFooterImage: false,
    });

    if (user) {
      navigate("/");
    }
  }, []);

  const handleSignIn = () => {
    const { email, password } = data;
    setLoader(true);

    signInEmailAndPassword(email, password, (data, success) => {
      if (success) {
        if (data.email) {
          updateUser(data);
          navigate("/checkout");
        }
        setData({
          email: "",
          password: "",
        });
        setErrorMessage("");
      } else {
        setErrorMessage(data);
        console.log("Error signing in:", data);
      }
      setLoader(false);
    });
  };

  return (
    <div
      className={`my-[50px] md:my-[100px] space-y-4 ${centerClass} md:w-[400px] text-center md:text-left text-text-9 flex justify-center items-center flex-col`}
    >
      <span className="text-[32px] font-bold">Welcome to FreeSIM</span>
      <span className="text-xl font-medium !mb-4">Login</span>

      {formArr.map((item, index) => (
        <InputComp
          key={index}
          type={item.type}
          placeholder={item.placeholder}
          handleChange={(value) => setData({ ...data, [item.type]: value })}
        />
      ))}

      <ButtonComp
        handleClick={handleSignIn}
        text="Login"
        className="w-full"
        loader={loader}
        disabled={!data.email || !data.password || loader}
      />

      {errorMessage && (
        <div className="text-red-500 text-sm mb-4">{errorMessage}</div>
      )}

      <span
        className="text-base !mt-10 font-semibold linearText cursor-pointer"
        onClick={() => navigate("/signup")}
      >
        No account? Sign Up
      </span>
      <span
        className="text-base font-semibold linearText cursor-pointer"
        onClick={() => navigate("/resetPass")}
      >
        I forgot my password
      </span>
      {/* <span
          className="text-base font-medium text-black  cursor-pointer"
         
        >
          Don’t want to log in with a password?
        </span>
        <span
          className="text-base font-medium linearText cursor-pointer"
          onClick={() => {}}
        >
          Continue with only your email instead
        </span> */}
    </div>
  );
};

export default SignIn;
