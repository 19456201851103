import React, { useEffect, useState } from "react";
import {
  AdsPoster,
  Features,
  TopCountries,
  FaqSection,
  Testimonials,
  HeroSection,
} from "../content/HomePage";
import { ButtonComp } from "../components";
import { centerClass } from "../utils";
import { useDataStore } from "../store";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const HomeScreen = () => {
  const { setFooter } = useDataStore();
  const [isHovered, setIsHovered] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setFooter({
      isFooter: true,
      isFooterImage: true,
    });
  }, []);

  return (
    <div className="w-full">
      <div
        className={`flex flex-col pt-[50px] items-center text-text-1 space-y-4 ${centerClass}`}
      >
        <div className="px-4 py-[10px] border border-border-2 rounded-full">
          <span className="md:text-lg text-[10px]">
            <span className="text-text-2 font-bold">Upgrade </span>
            your SIM with Simfree today!
          </span>
        </div>

        <div className="font-bold text-center md:text-[100px] text-[56px] leading-[100%]">
          <p>Simfree - Connect</p>
          <p>Globally, Roam Locally!</p>
        </div>

        <span className="font-medium md:text-[32px] text-2xl text-center !mt-8">
          Unlock the power of eSIM & Simplify Your Global Connectivity
        </span>

        <motion.span
          className="border-dashed border border-text-4 p-3 rounded-2xl cursor-pointer"
          onClick={() => navigate("/destinations")}
          whileHover={{ backgroundColor: "rgb(255 190 46 / 0.25)" }}
          transition={{ duration: 0.5 }}
        >
          <ButtonComp
            text="Get your eSIM"
            className="text-xl"
            bgColor="s"
            animate={{
              onHoverStart: () => setIsHovered(true),
              onHoverEnd: () => setIsHovered(false),
              animate: {
                backgroundColor: isHovered ? "#FFAF00" : "#FFCA5F",
                color: isHovered ? "#fff" : "",
              },
              transition: { duration: 0.5 },
            }}
          />
        </motion.span>

        <HeroSection />
      </div>

      <div id="getStarted">
        <TopCountries />
      </div>
      <Features />
      <AdsPoster />
      <Testimonials />
      <div id="faqSection">
        <FaqSection />
      </div>
    </div>
  );
};

export default HomeScreen;
