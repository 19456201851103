import React from "react";
import Rocket from "../assets/images/RocketColor.png";
import { centerClass } from "../utils";
import LazyImage from "./LazyImage";

export default function SuccesForget() {
  return (
    <div
      className={`my-[50px] md:my-[100px] space-y-4 ${centerClass} md:w-[400px] text-center md:text-left text-text-9 flex justify-center items-center flex-col`}
    >
      <p className="text-[32px] font-bold leading-8 text-center text-text-1">
        Your password reset email is on the way
      </p>
      <p className="text-[16px] font-medium leading-5 text-center text-black">
        Go check your inbox, and we can't wait for you to join us again
      </p>
      <LazyImage className="max-w-[112px] min-h-[157px]" src={Rocket} />
      <div className="w-[100%] space-y-3">
        <p className="text-[16px] font-medium leading-5 text-center text-black">
          Didn’t get an email? You might need to sign up for account. If you
          already have one, try logging in with a different login method
        </p>
        {/* <p className="text-[16px] font-medium leading-5 text-center linearText">
          Continue with only your email instead
        </p> */}
      </div>
    </div>
  );
}
