import React from "react";

const ESimIcon = ({
  height = "1em",
  fill = "currentColor",
  focusable = "false",
  ...props
}) => {
  return (
    <svg
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={height}
      focusable={focusable}
      {...props}
    >
      <path
        fill={fill}
        d="M18 4v16H6V8.8L10.8 4zm0-2h-8L4 8v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2M9.5 19h-2v-2h2zm7 0h-2v-2h2zm-7-4h-2v-4h2zm3.5 4h-2v-4h2zm0-6h-2v-2h2zm3.5 2h-2v-4h2z"
      />
    </svg>
  );
};

export default ESimIcon;
