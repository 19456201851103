import React from "react";

function MenuIcon({ className = "w-6 h-6" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
      className={className}
    >
      <path
        stroke="#111"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M3.5 14h21m-21-7h21m-14 14h14"
      ></path>
    </svg>
  );
}

export default MenuIcon;
